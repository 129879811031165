<mat-chip-listbox [(ngModel)]="selectedToggle" aria-label="Selection" class="workflow-chips">
    <mat-chip-option (selectionChange)="onChipSelectChange($event)" value="tasks"
        >{{ 'COMPONENT.PAGE_ORGA.tasks' | translate }}
    </mat-chip-option>
    <mat-chip-option value="medical_orders">{{ 'COMPONENT.PAGE_ORGA.medical_orders' | translate }} </mat-chip-option>

    <mat-chip-option value="comments">{{ 'COMPONENT.PAGE_ORGA.comments' | translate }} </mat-chip-option>
    <mat-chip-option value="diagnostic_findings"
        >{{ 'COMPONENT.PAGE_ORGA.diagnostic_findings' | translate }}
    </mat-chip-option>
</mat-chip-listbox>

<div [ngSwitch]="selectedToggle" class="chip-container">
    <div *ngSwitchCase="'tasks'">
        <app-orga-tabs-tasks [tasks]="tasks" (clickOnTask)="onClickOnTask($event)"></app-orga-tabs-tasks>
    </div>
</div>
