import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-media-toolbar',
    templateUrl: './media-toolbar.component.html',
    styleUrls: ['./media-toolbar.component.scss'],
    standalone: true,
    imports: [MatIcon, MatIconButton, MatTooltip],
})
export class MediaToolbarComponent implements OnInit {
    @Output() clickOnOpenPaintingTool: EventEmitter<void> = new EventEmitter<void>();
    @Output() clickOnOpenFullscreen: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    public onClickOnOpenPaintingTool() {
        this.clickOnOpenPaintingTool.emit();
    }

    public onClickOnOpenFullscreen() {
        this.clickOnOpenFullscreen.emit();
    }
}
