import { FormioModule } from '@formio/angular';
import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import {
    FormioRendererComponent,
    FormioRendererData,
    FormioRendererI18n,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBadgeModule } from '@angular/material/badge';
import { VC_CommunicationMessage } from '../../models/view-content.models/view-content.model';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { User } from '../../models/cp2-api.model';

export interface FormModalResult {
    role: 'cancel' | 'save';
    data: any;
}

@Component({
    selector: 'app-communication-message-modal',
    standalone: true,
    templateUrl: './communication-message-modal.component.html',
    styleUrls: ['./communication-message-modal.component.scss'],
    imports: [
        CommonModule,
        FormioModule,
        FormioRendererComponent,
        FormsModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        ScrollingModule,
        TranslateModule,
    ],
})
export class CommunicationMessageModalComponent<T> implements OnInit, AfterContentInit {
    private static readonly TAG = 'FormModalComponent';

    public form: any;
    public formData: any;
    public renderOptions: any = {};
    public form_file_name: string = '';
    public i18n: FormioRendererI18n | undefined;
    public recipient: User | undefined;
    public viewContentI18n: FormioRendererI18n;
    public formioRendererData: FormioRendererData[] = [];

    public constructor(
        public dialogRef: MatDialogRef<FormModalResult>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            form_file_name: string;
            form_data?: any;
            viewContentI18n: FormioRendererI18n;
            answersTo: VC_CommunicationMessage | undefined;
            recipientList: User[];
        },
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.viewContentI18n = data.viewContentI18n;
        this.formData = data.form_data;
        this.form_file_name = data.form_file_name;
    }

    public async ngOnInit(): Promise<void> {
        await this.loadJSON();
        if (this.data.form_data) {
            this.buildFormioRendererData();
        }

        if (this.data.recipientList.length) this.recipient = this.data.recipientList[0];
    }

    public ngAfterContentInit(): void {
        this.setLanguage();
    }

    //#region Listeners

    public onClickOnCancel(): void {
        this.dialogRef.close();
    }

    public onClickOnSave(): void {
        const data = [...this.formioRendererData];
        data.push({ key: 'contractor', value: this.recipient?.id });
        this.dialogRef.close({ role: 'save', data });
    }

    public onDataChange(updatedData: FormioRendererData[]) {
        this.formioRendererData = updatedData;
    }

    //#endregion

    private buildFormioRendererData(): void {
        if (!this.data.form_data) return;

        this.formioRendererData = [];
        const keys = Object.keys(this.data.form_data.data);
        for (const key of keys) {
            const value = this.data.form_data.data[key];
            this.formioRendererData.push({ key, value });
        }
    }

    private async loadJSON(): Promise<void> {
        const content = await (await fetch('assets/forms/' + this.data.form_file_name)).json();
        this.form = content.form;
        this.renderOptions.i18n = content.i18n;
        this.i18n = content.i18n;
    }

    private setLanguage(lang?: string) {
        if (!lang) {
            lang = this.translate.currentLang;
        }
        this.renderOptions.language = lang;
    }
}
