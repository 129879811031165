<div class="flex flex-row justify-between">
    <mat-chip-listbox>
        <div class="flex flex-col gap-1">
            <div class="flex flex-row gap-1">
                <ng-container *ngFor="let region of regions">
                    <mat-chip-option (click)="selectRegion(region)" [selectable]="!region.selected" [selected]="region.selected" class="shadow">
                        <span class="font-light">{{ region.long_text ?? region.short_text }}</span>
                    </mat-chip-option>
                </ng-container>
            </div>
            <div>
                <mat-chip-option (selectionChange)="clickOnOpenLaborViewer($event)" [disabled]="disableLabViewerButton" class="shadow">
                    <span class="font-light">Laborviewer</span>
                </mat-chip-option>
            </div>
        </div>
    </mat-chip-listbox>
    <div class="flex flex-row self-start items-center">
        <button (click)="onClickOpenTextEditor()" mat-icon-button matTooltip="Dokument erstellen">
            <mat-icon>note_add</mat-icon>
        </button>
        <button (click)="onClickOpenDoctorFormular()" mat-icon-button matTooltip="Arztbericht erstellen">
            <mat-icon svgIcon="prescriptions"></mat-icon>
        </button>
        <button (click)="onClickOpenNurseFormular()" mat-icon-button matTooltip="Pflegebericht erstellen">
            <mat-icon svgIcon="clinical_notes"></mat-icon>
        </button>

        <mat-select
            (selectionChange)="selectRecord($event.value)"
            [value]="getSelectedRecord()"
            class="!w-32 bg-white p-2 !font-light !shadow-sm"
        >
            <ng-container *ngFor="let record of records">
                <mat-option [value]="record">{{ record?.name }}</mat-option>
            </ng-container>
        </mat-select>

    </div>
</div>
