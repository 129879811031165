import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccessFacadeService } from '../services/facades/access-facade.service';
import { ServerSettingsFacadeService } from '../services/facades/server-settings-facade.service';
import { filter, firstValueFrom } from 'rxjs';
import * as jose from 'jose';
import dayjs from 'dayjs';

export const authGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const accessFacade = inject(AccessFacadeService);
    const serverSettingsF = inject(ServerSettingsFacadeService);
    const accessToken$ = accessFacade.userTokens$;

    let token = null;

    try {
        const accessToken = await firstValueFrom(accessToken$.pipe(filter((e) => !e.loading)));
        token = accessToken.token;
    } catch (e) {
        console.error(e);
    }

    let isValidToken = false;
    if (!!token?.access_token) {
        const decoded = jose.decodeJwt(token.access_token);
        const exp = decoded['exp'] ?? 0;
        const date = dayjs(exp * 1000);
        isValidToken = date.isAfter(dayjs());
    }

    if (isValidToken) {
        // Token valid, allow navigation
        return true;
    } else {
        // No token, redirect to login
        const configUrl = serverSettingsF.snapshot?.loginConfigUrl;
        console.log('No token, redirecting to login', configUrl);
        return router.navigate(['/login'], { queryParams: { configUrl } });
    }
};
