import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ServerSettingsActions from '../actions/server-settings.action';
import { DataRepositoryService } from '../../services/datarepository.service';
import { AppState } from '../appstate.model';

@Injectable()
export class ServerSettingsEffects {
    loadServerSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServerSettingsActions.loadServerSettings),
            switchMap(() =>
                from(this.repo.getServerSettings()).pipe(
                    map((serverSettings) => ServerSettingsActions.loadServerSettingsSuccess({ serverSettings })),
                    catchError((error) => of(ServerSettingsActions.loadServerSettingsFail({ error })))
                )
            )
        )
    );

    updateServerSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ServerSettingsActions.updateServerSettings),
            switchMap(({ serverSettings }) =>
                from(this.repo.saveServerSettings(serverSettings)).pipe(
                    map(() => ServerSettingsActions.updateServerSettingsSuccess({ serverSettings })),
                    catchError((error) => of(ServerSettingsActions.updateServerSettingsFail({ error })))
                )
            )
        )
    );

    public constructor(
        private actions$: Actions,
        private repo: DataRepositoryService,
        private store: Store<AppState>
    ) {}
}
