export const environment = {
    apiServer: 'https://dev-api.cpad2.de',
    cpad2Server: 'https://dev.cpad2.de/#/',
    cpad2ServerGenerateJWT: true,
    // apiServer: 'https://test-api.cpad2.de',
    cpad2ServerSubscriberTokenUrl: '/api/subscriberToken',
    mercureBaseUrl: 'https://dev.cpad2.de/.well-known/mercure',
    mercureSubscriberToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyIqIl19fQ.sPE_gsY3x4Wmjcje2o6aqWeupWxx1m1p57aDXrTRrJE',
    production: false,
    smartSettingsServer: 'https://dev-api.cpad2.de/.well-known/smart-configuration',
};
