import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Cp2MatPaginatorIntl } from '../../../shared/Cp2MatPaginatorIntl';
import { MatChipSelectionChange, MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { OrgaTabsTasksComponent } from '../orga-tabs-tasks/orga-tabs-tasks.component';
import { FormioRendererI18n } from '../../data-interaction/formio-renderer/formio-renderer.component';
import {
    Patient_Details,
    VC_CommunicationMessage,
    VcPatientListItem,
    ViewContent,
} from '../../../models/view-content.models/view-content.model';
import { MatDialog } from '@angular/material/dialog';
import { FormModalComponent } from '../../../modals/form-modal/form-modal.component';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-workflow-tabs',
    templateUrl: './workflow-tabs.component.html',
    styleUrls: ['./workflow-tabs.component.scss'],
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        CommonModule,
        MatChipsModule,
        OrgaTabsTasksComponent,
        CommonModule,
        MatButtonToggleModule,
        FormsModule,
    ],
    providers: [{ provide: MatPaginatorIntl, useClass: Cp2MatPaginatorIntl }],
})
export class WorkflowTabsComponent implements OnChanges {
    @ViewChild(MatSort) sort: MatSort | null = null;
    @ViewChild(MatTable) table: MatTable<any> | undefined;

    /** A string value used to filter the table data. This value is bound to a text input for dynamic filtering. */
    @Input() filterValue: string = '';
    @Input() patientDetails: Patient_Details | undefined;
    @Input() tasks: VC_CommunicationMessage[] = [];
    /**
     * An array of `PatientListItem` objects to be displayed in the table. This data is passed into the component
     * and used to populate the rows of the table. Since the workflow page and orga share the same data structure
     * so the interface PatientListItem is here
     */
    @Input() workflowDataPatients: VcPatientListItem | undefined;
    @Input() workflowDataPatientsDetails: Patient_Details[] = [];
    /** for the material chips to switch between 4 chips */
    @Input() selectedToggle: string | null | undefined = 'tasks';
    @Input() viewContentI18n: FormioRendererI18n | undefined;

    @Output() selectedIcon: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectionChange: EventEmitter<MatChipSelectionChange> | undefined;
    @Output() viewContentChange = new EventEmitter<ViewContent<unknown>>();

    public constructor(public dialog: MatDialog) {}

    public ngOnChanges(changes: SimpleChanges): void {
        console.log({ changes });
    }

    //#region Listeners
    public async onClickOnTask($event: VC_CommunicationMessage): Promise<void> {
        const dialogRef = this.dialog.open(FormModalComponent, {
            data: {
                form_data: { data: $event.data },
                form_file_name: 'form_tasks.json',
                history: $event.history,
                viewContentI18n: $event.i18n,
                vcPatientDetails: this.patientDetails,
            },
            maxHeight: 'calc(100vh - 16px)',
            panelClass: 'patient-overview-dialog-container',
        });

        const res = await firstValueFrom(dialogRef.afterClosed());

        if (!res || res.role === 'cancel') return;

        const d = $event.data;
        d.done = res.data.find((e: { key: string; value: any }) => e.key === 'done')?.value;
        d.editor = res.data.find((e: { key: string; value: any }) => e.key === 'editor')?.value;
        d.details = res.data.find((e: { key: string; value: any }) => e.key === 'details')?.value;
        d.priority = res.data.find((e: { key: string; value: any }) => e.key === 'priority')?.value;
        d.task_name = res.data.find((e: { key: string; value: any }) => e.key === 'task_name')?.value;
        d.contractor = res.data.find((e: { key: string; value: any }) => e.key === 'contractor')?.value;
        d.goalDateOne = res.data.find((e: { key: string; value: any }) => e.key === 'goalDateOne')?.value;

        this.viewContentChange.emit($event);
    }

    public onChipSelectChange(event: MatChipSelectionChange) {
        this.selectionChange?.emit(event);
    }
    //#endregion
}
