import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface AlertConfig {
    header?: string;
    message?: string;
    buttons?: AlertButton[];
    inputs?: AlertInput[];
    disableBackdrop?: boolean;
}

export interface AlertInput {
    label?: string;
    placeholder?: string;
    name: string;
    value?: string;
}

export interface AlertButton {
    role: string;
    text: string;
}

export interface AlertResult {
    role: string;
    inputs: AlertInput[];
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatInputModule],
})
export class AlertComponent {
    public headerText: string = '';
    public labelText: string = '';
    public icon: string = '';

    public constructor(
        public dialogRef: MatDialogRef<{ role: string } | undefined>,
        @Inject(MAT_DIALOG_DATA)
        public data: AlertConfig
    ) {
        if (data.disableBackdrop) {
            dialogRef.disableClose = true;
        }
    }

    //#region Listeners
    public onClickOnButton(button: any): void {
        const result: AlertResult = {
            role: button.role,
            inputs:
                this.data.inputs?.map((input: any) => ({
                    name: input.name,
                    value: input.value,
                })) || [],
        };
        this.dialogRef.close(result);
    }

    public onInputChange(inputName: string, $event: any): void {
        const input = this.data.inputs?.find((i: any) => i.name === inputName);
        if (input) {
            input.value = $event.target.value;
        }
    }
    //#endregion
}
