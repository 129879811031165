import { createReducer, on } from '@ngrx/store';
import { ServerSettings } from '../../models/server-settings.model';
import * as ServerSettingsActions from '../actions/server-settings.action';

export interface ServerSettingsState {
    serverSettings: ServerSettings | undefined;
    loading: boolean;
    error: any;
}

export const initialState: ServerSettingsState = {
    serverSettings: undefined,
    loading: false,
    error: null,
};

export const serverSettingsReducer = createReducer(
    initialState,
    on(ServerSettingsActions.loadServerSettings, (state) => ({
        ...state,
        loading: true,
        error: null,
    })),
    on(ServerSettingsActions.loadServerSettingsSuccess, (state, { serverSettings }) => ({
        ...state,
        serverSettings,
        loading: false,
    })),
    on(ServerSettingsActions.loadServerSettingsFail, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    })),
    on(ServerSettingsActions.updateServerSettingsSuccess, (state, { serverSettings }) => ({
        ...state,
        serverSettings,
        loading: false,
        error: null,
    })),
    on(ServerSettingsActions.updateServerSettingsFail, (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }))
);
