<div class="page-container !bg-[var(--color-background-primary-color)] max-h-screen">
    <div class="content-container flex-grow">
        <div class="center-container">
            <div>
                <img alt="" class="w-[40%] block mx-auto" src="../../../../assets/img/logo-checkpad-triosys.png" />
            </div>
            <div class="center-content">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                <span class="waiting-label !text-gray-700">
                    {{ 'GENERAL.label_waiting' | translate }}
                </span>
            </div>
        </div>
    </div>
    <div class="footer text-sm !text-gray-700 !bg-[var(--color-card-primary-color)] py-2">
        &copy; {{ currentDate | date: 'YYYY' }} triosys GmbH
    </div>
</div>
