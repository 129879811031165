<div class="conversation-tree-node-messages-container">
    <div *ngIf="node" class="conversation-tree-node-message-content">
        <!-- Name -->
        <div class="conversation-tree-node-message-field">
            <span class="conversation-tree-node-message-field-label"
                >Aufgabenname:</span
            >
            <span class="conversation-tree-node-message-field-value"
                >{{ node.m.data.task_name }}
            </span>
        </div>

        <!-- Details -->
        <div class="conversation-tree-node-message-field">
            <span class="conversation-tree-node-message-field-label"
                >Aufgabendetails:</span
            >
            <span class="conversation-tree-node-message-field-value"
                >{{ node.m.data.details }}
            </span>
        </div>

        <!-- Editor -->
        <div class="conversation-tree-node-message-field">
            <span class="conversation-tree-node-message-field-label"
                >Editor:</span
            >
            <span class="conversation-tree-node-message-field-value"
                >{{ node.m.data.editor?.first_name }}&nbsp;{{ node.m.data.editor?.last_name }}
            </span>
        </div>

        <!-- Goal date -->
        <div class="conversation-tree-node-message-field">
            <span class="conversation-tree-node-message-field-label"
                >Zieldatum:</span
            >
            <span class="conversation-tree-node-message-field-value"
                >{{ node.m.data.goalDateOne | date : "dd.MM.yyyy" }}
            </span>
        </div>

        <!-- Priority -->
        <div class="conversation-tree-node-message-field">
            <span class="conversation-tree-node-message-field-label"
                >Priorität:</span
            >
            <span class="conversation-tree-node-message-field-value"
                >{{  'GENERAL.priority_' + node.m.data.priority | translate }}
            </span>
        </div>
    </div>

    <button *ngIf="!node?.responses?.length && node?.m?.data?.editor !== currentUser?.id"
     mat-flat-button (click)="onClickOnRespond()">Antworten</button>

    <app-conversation-tree-node
        *ngFor="let child of node?.responses"
        [node]="child"
        (clickOnRespond)="forwardClick($event)"
    ></app-conversation-tree-node>
</div>
