import { Platform } from '@ionic/angular';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { LuicModule } from '@lohmann-birkner/luic';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { CapacitorHttp } from '@capacitor/core';
import { ServerSettingsSchema } from '../../../models/server-settings.model';
import { ServerSettingsFacadeService } from '../../../services/facades/server-settings-facade.service';
import { AlertService } from '../../../services/alert.service';
import { boolean } from 'zod';

@Component({
    selector: 'app-login-page',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        LuicModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinner,
        TranslateModule,
    ],
    templateUrl: './login-page.component.html',
    styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
    public currentDate = new Date();
    protected readonly window = window;

    public constructor(
        private alert: AlertService,
        private authService: AuthService,
        private platform: Platform,
        private route: ActivatedRoute,
        private serverSettingsFacade: ServerSettingsFacadeService,
        private translate: TranslateService
    ) {
        this.init();

        if (this.platform.is('electron')) {
            window.addEventListener('message', (event) => {
                if (event.data.type === 'popupClosed') {
                    const popupUrl = event.data.url;
                    this.authService.initializeLoginCallback(popupUrl).then();
                }
            });
        }
    }

    private async init(): Promise<void> {
        await this.loadSettings();
        await this.authService.initAuth();
    }

    private isValidUrl(url: string | URL) {
        try {
            const newUrl = new URL(url);
            return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
        } catch (err) {
            return false;
        }
      }

    private async loadSettings(): Promise<void> {
        // Check if query params contain a config URL
        let url: string | undefined | null = this.route.snapshot.queryParamMap.get('configUrl');

        // If no URL is provided in the query params, try to get it from the server settings locally stored
        if (!url) url = this.serverSettingsFacade.snapshot?.loginConfigUrl;

        // If no URL is provided in the query params, show an alert to ask for it
        if (!url) url = await this.promptForConfigUrl();

        // Check URL for validity with a simple regex (check for http(s)://someDomain.someTLD)
        if (url && !this.isValidUrl(url)) url = null;

        // If URL is still not provided or not valid, show an alert
        if (!url) {
            await this.alert.show({
                message: this.translate.instant('COMPONENT.LOGIN_PAGE.alert_message_no_valid_url'),
                buttons: [{ role: 'ok', text: this.translate.instant('GENERAL.button_text_ok') }],
            });
            return;
        }

        await this.retrieveServerSettingsOrShowAlert(url);
    }

    private async retrieveServerSettingsOrShowAlert(url: string) {
        try {
            const res = await CapacitorHttp.get({ url });
            const result = ServerSettingsSchema.safeParse(res.data);
            if (result.success) {
                this.serverSettingsFacade.saveServerSettings(result.data);
            } else {
                // Show an alert if the server settings could not be parsed
                await this.alert.show({
                    message: this.translate.instant('COMPONENT.LOGIN_PAGE.alert_message_no_valid_settings'),
                    buttons: [{ role: 'ok', text: this.translate.instant('GENERAL.button_text_ok') }],
                });
            }
        } catch (e) {
            console.error('Error fetching or parsing JSON:', e);
            await this.alert.show({
                message: this.translate.instant('COMPONENT.LOGIN_PAGE.alert_message_no_valid_url'),
                buttons: [{ role: 'ok', text: this.translate.instant('GENERAL.button_text_ok') }],
            });
        }
    }

    private async promptForConfigUrl(): Promise<string | undefined> {
        const alertRes = await this.alert.show({
            message: this.translate.instant('COMPONENT.LOGIN_PAGE.alert_message_config_url'),
            inputs: [{ label: 'URL', name: 'url' }],
            buttons: [
                { role: 'ok', text: this.translate.instant('GENERAL.button_text_ok') },
                { role: 'cancel', text: this.translate.instant('GENERAL.button_text_cancel') },
            ],
            disableBackdrop: true,
        });

        if (alertRes?.role === 'ok') {
            const urlInput = alertRes?.inputs?.find((input) => input.name === 'url');
            if (urlInput) return urlInput.value;
        }

        return undefined;
    }
}
