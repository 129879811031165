import { createAction, props } from '@ngrx/store';
import { ServerSettings } from '../../models/server-settings.model';

export const loadServerSettings = createAction('[Server Settings] Load Server Settings');

export const loadServerSettingsSuccess = createAction(
    '[Server Settings] Load Server Settings Success',
    props<{ serverSettings: ServerSettings }>()
);

export const loadServerSettingsFail = createAction(
    '[Server Settings] Load Server Settings Fail',
    props<{ error: any }>()
);

export const updateServerSettings = createAction(
    '[Server Settings] Update Server Settings',
    props<{ serverSettings: ServerSettings }>()
);

export const updateServerSettingsSuccess = createAction(
    '[Server Settings] Update Server Settings Success',
    props<{ serverSettings: ServerSettings }>()
);

export const updateServerSettingsFail = createAction(
    '[Server Settings] Update Server Settings Fail',
    props<{ error: any }>()
);
