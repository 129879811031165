import { createSelector } from '@ngrx/store';
import { AppState } from '../appstate.model';

export const selectServerSettingsFeature = (state: AppState) => state.serverSettings;

export const selectServerSettings = createSelector(
    selectServerSettingsFeature,
    (serverSettingsState) => serverSettingsState.serverSettings
);

export const selectServerSettingsLoading = createSelector(
    selectServerSettingsFeature,
    (serverSettingsState) => serverSettingsState.loading
);

export const selectServerSettingsError = createSelector(
    selectServerSettingsFeature,
    (serverSettingsState) => serverSettingsState.error
);
