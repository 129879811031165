import { CommunicationComponent } from '../../components/patient-info/communication/communication.component';
import { FormioRendererI18n } from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import {
    Case_Overview,
    getNewViewContent,
    Patient_Details,
    VC_Case,
    VC_CommunicationMessage,
    VC_Consultation,
    VC_CurveGroup,
    VC_Diagnose,
    VC_Diagnostic,
    VC_Discharge,
    VC_Medical_History,
    VC_Pathogen,
    VC_Post_Treatment,
    VC_Therapy,
    ViewContent,
} from '../../models/view-content.models/view-content.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Finding, PatientRecordVCData, Record, Region, SubRegion } from '../../models/patient-records.model';
import { PatientCurveComponent } from '../../components/patient-info/patient-curve-group/patient-curve-group.component';
import { CurveGroup, getNewCurveGroup, getNewCurveRow } from '../../models/curve.model';
import { v4 as uuidv4 } from 'uuid';
import { PatientListItem } from '../../models/patient.model';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { PatientOverviewComponent } from '../../components/patient-info/patient-overview/patient-overview.component';
import { MatIconModule } from '@angular/material/icon';
import { WorkflowTabsComponent } from '../../components/tabs/workflow-tabs/workflow-tabs.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Cp2ApiService } from '../../services/cp2-api.service';
import { SettingsFacadeService } from '../../services/facades/settings-facade.service';
import dayjs from 'dayjs';
import { AccessFacadeService } from '../../services/facades/access-facade.service';
import { CP2_User } from '../../models/view-content.models/view-content-personal-domain.model';
import * as jose from 'jose';
import { UserToken } from '../../models/auth.model';
import { ToolboxService } from '../../services/toolbox.service';
import {
    Consultation,
    Diagnose,
    Diagnostic,
    Discharge,
    LabReport,
    Medical_History,
    Pathogen,
    Post_Treatment,
    Therapy,
    Visit_Record,
} from '../../models/view-content.models/view-content-clinic-domain.model';
import { ViewContentCacheService } from '../../services/cache/view-content-cache.service';
import { CommunicationMessage } from '../../models/communication.model';
import { PatientInfoDetailsComponent } from '../../components/patient-info/patient-info-details/patient-info-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MedViewComponent } from '../med-view/med-view.component';

export interface PatientNameAndDob {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    ward: string;
    room: string;
    bed: string;
}

export interface PatientRecordsTemplate {
    templateLabel: string;
    templateId: string;
    templatePath: string;
}

export interface PatientData {
    record: Record[];
    regions: Region[];
    subRegion: SubRegion[];
    finding: ViewContent<PatientRecordVCData>[];
    labor: ViewContent<LabReport>;
    pid: string;
    cid: string;
}

@Component({
    selector: 'app-patient-details',
    templateUrl: './patient-details.component.html',
    styleUrls: ['./patient-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        CommunicationComponent,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        PatientCurveComponent,
        PatientInfoDetailsComponent,
        PatientOverviewComponent,
        TranslateModule,
        WorkflowTabsComponent,
        MedViewComponent,
    ],
    providers: [
        // Provide DialogRef token
        { provide: DialogRef, useValue: {} },
        { provide: DIALOG_DATA, useValue: {} },
    ],
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
    public vcPatientDetails: Patient_Details | undefined;
    public vcCaseOverview: Case_Overview | undefined;
    public vc_CurveGroups: VC_CurveGroup[] = [];
    public vc_CommunicationMessages: VC_CommunicationMessage[] = [];
    public loadingOverview = false;
    public loadingCurve = false;
    public loadingRecord = false;
    public loadingWorkflow = false;
    public loadingCommunication = false;

    public currentTab: number | undefined;

    public records: Record[] = [];
    public regions: Region[] = [];
    public subRegions: SubRegion[] = [];
    public findings: Finding[] = [];

    //TODO: need to be deleted
    public patient: PatientListItem | undefined;
    public viewContentI18n: FormioRendererI18n | undefined;
    public patientNameAndDob: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        ward: '',
        room: '',
        bed: '',
    };
    public filterValue: string = '';
    public vcFindings: ViewContent<PatientRecordVCData>[] = [];
    public vcLabor: ViewContent<LabReport> = {} as ViewContent<LabReport>;
    public user: CP2_User | undefined;

    public patientRecordData: PatientData = {} as PatientData;

    private allSubs: Subscription[] = [];
    private caseId: string | undefined;
    private caseDetails: VC_Case | undefined;
    private token: UserToken | undefined;
    private allLocators: string[] = [];
    private areaSet = new Set<string>();
    private subAreaSet = new Set<string>();
    private recordSet = new Set<string>();
    private laborVc: any = {};

    public constructor(
        private accessFacade: AccessFacadeService,
        private api: Cp2ApiService,
        private location: Location,
        private route: ActivatedRoute,
        private settings: SettingsFacadeService,
        private toolbox: ToolboxService,
        private vcCache: ViewContentCacheService
    ) {
        this.settings.loadSettings();

        this.vcCache.incomingViewContents$
            .pipe(
                // Select only locators related to the current caseId
                filter((vc) => {
                    if (!this.caseId) return false;
                    return vc.locator.includes(this.caseId);
                })
            )
            .subscribe((vc) => this.processIncomingVc(vc));
    }

    /**
     * Initializes the component, fetches patient details, and calculates age.
     * @private
     */
    public async ngOnInit(): Promise<void> {
        this.caseId = this.route.snapshot.paramMap.get('id') as string | undefined;

        // Listen for changes in the route parameters
        const tabIndex = Number.parseInt(this.route.snapshot.paramMap.get('tabIndex') ?? '0');
        this.changeTab(tabIndex);

        // Load this.user
        firstValueFrom(this.accessFacade.userTokens$)
            .then(async (t) => {
                if (!t?.token) return;

                const { access_token } = t.token;
                const decodedToken = jose.decodeJwt(access_token);

                this.user = {
                    id: t.token.related_user_id,
                    uuid: decodedToken['sub'] ?? '',
                    surname: decodedToken['family_name'] as string,
                    name: decodedToken['given_name'] as string,
                };

                this.token = t.token;

                if (access_token && this.caseId) {
                    try {
                        await this.refresh();
                    } catch (e) {
                        console.error('ERROR TO LOAD DATA!:', e);
                    }
                }
            })
            .catch((error) => {
                console.error('Error processing user tokens:', error);
            });
    }

    /**
     * Cleans up resources on component destruction.
     */
    public ngOnDestroy(): void {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    //#region Listeners
    public async onCurveGroupsChange($event: VC_CurveGroup): Promise<void> {
        if (!this.caseId || !this.caseDetails?.related_patient_id || !this.user)
            throw Error('Error saving curve group: no case_id or patient_id or user available');

        this.vcCache.saveViewContent($event);
    }

    public async onOverviewDataChange(ev: any) {
        if (!this.caseId || !this.caseDetails?.related_patient_id || !this.user)
            throw Error('Error saving curve group: no case_id or patient_id or user available');
        const cid = this.caseDetails?.related_case_id;
        const pid = this.caseDetails?.related_patient_id;
        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        if (this.token) {
            const method: 'UPDATE' | 'ADD' = ev.method;
            switch (ev.type) {
                case 'task':
                    const task: CommunicationMessage = {
                        details: ev.data.find((item: any) => item.key === 'details')?.value,
                        task_name: ev.data.find((item: any) => item.key === 'task_name')?.value,
                        priority: ev.data.find((item: any) => item.key === 'priority')?.value,
                        goalDateOne: ev.data.find((item: any) => item.key === 'goalDateOne')?.value,
                        done: ev.data.find((item: any) => item.key === 'done')?.value,
                        editor: ev.data.find((item: any) => item.key === 'editor')?.value,
                        contractor: ev.data.find((item: any) => item.key === 'contractor')?.value,
                        answersTo: ev.data.find((item: any) => item.key === 'answersTo')?.value,
                    };

                    let vcTask: ViewContent<CommunicationMessage> = {} as ViewContent<CommunicationMessage>;
                    if (method === 'ADD') {
                        vcTask = getNewViewContent<CommunicationMessage>({
                            locator: `case.communication.tasks.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: task,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'not_final',
                            i18n,
                        });

                        await this.vcCache.saveViewContent(vcTask);
                    } else if (method === 'UPDATE') {
                        vcTask = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: task,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });

                        await this.vcCache.saveViewContent(vcTask);
                    }

                    break;

                case 'diagnostic':
                    const diagnostic: Diagnostic = {
                        title: ev.data.find((item: any) => item.key === 'title')?.value ?? '',
                        details: ev.data.find((item: any) => item.key === 'details')?.value ?? '',
                        task: ev.data.find((item: any) => item.key === 'task')?.value ?? false,
                        teams:
                            ev.data.find((item: any) => item.key === 'teams')?.value ??
                            ({} as {
                                [key: string]: string | boolean;
                            }),
                        registered: ev.data.find((item: any) => item.key === 'registered')?.value ?? false,
                        conducted: ev.data.find((item: any) => item.key === 'conducted')?.value ?? false,
                        goalDateOn: ev.data.find((item: any) => item.key === 'goalDateOn')?.value ?? '',
                        goal_date: ev.data.find((item: any) => item.key === 'goal_date')?.value ?? '',
                        showDayCountForGoalDateAs:
                            ev.data.find((item: any) => item.key === 'showDayCountForGoalDateAs')?.value ?? 'day0',
                        dayCountDeactivate: ev.data.find((item: any) => item.key === 'dayCountDeactivate')?.value ?? '',
                        showJustInCurve: ev.data.find((item: any) => item.key === 'showJustInCurve')?.value ?? false,
                    };

                    let vcDiagnostic: ViewContent<Diagnostic> = {} as ViewContent<Diagnostic>;
                    if (method === 'ADD') {
                        vcDiagnostic = getNewViewContent({
                            locator: `case.overview.diagnostic.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: diagnostic,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcDiagnostic);
                        // await this.api.postViewContent(vcDiagnostic, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcDiagnostic = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: diagnostic,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcDiagnostic);
                        // await this.api.putViewContent(vcDiagnostic, this.token.access_token);
                    }
                    break;

                case 'diagnoses':
                    const diagnose: Diagnose = {
                        created_at: new Date().toISOString(),
                        diagnose: ev.data.find((item: any) => item.key === 'diagnose')?.value ?? '',
                    };

                    let vcDiagnose: ViewContent<Diagnose> = {} as ViewContent<Diagnose>;

                    if (method === 'ADD') {
                        vcDiagnose = getNewViewContent({
                            locator: `case.overview.diagnosis.${this.caseId}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: diagnose,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'doctor',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcDiagnose);
                        // await this.api.postViewContent(vcDiagnose, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcDiagnose = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: diagnose,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.api.putViewContent(vcDiagnose, this.token.access_token);
                    }
                    break;

                case 'anamnesis':
                    const anamnesis: Medical_History = {
                        medical_history: ev.data.find((item: any) => item.key === 'medical_history')?.value ?? '',
                        created_at: new Date().toISOString(),
                        date: new Date().toISOString(),
                    };
                    let vcAnamnesis: ViewContent<Medical_History> = {} as ViewContent<Medical_History>;

                    if (method === 'ADD') {
                        vcAnamnesis = getNewViewContent({
                            locator: `case.overview.anamnesis.${this.caseId}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: anamnesis,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcAnamnesis);
                        // await this.api.postViewContent(vcAnamnesis, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcAnamnesis = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: anamnesis,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcAnamnesis);
                        // await this.api.putViewContent(vcAnamnesis, this.token.access_token);
                    }

                    break;

                case 'therapy':
                    const therapy: Therapy = {
                        conducted: ev.data.find((item: any) => item.key === 'conducted')?.value ?? false,
                        created_at:
                            ev.data.find((item: any) => item.key === 'created_at')?.value ?? new Date().toISOString(),
                        dayCountDeactivateOn:
                            ev.data.find((item: any) => item.key === 'dayCountDeactivateOn')?.value ??
                            new Date().toISOString(),
                        details: ev.data.find((item: any) => item.key === 'details')?.value ?? '',
                        editor: this.user,
                        goalDateOn:
                            ev.data.find((item: any) => item.key === 'goalDateOn')?.value ?? new Date().toISOString(),
                        registered: ev.data.find((item: any) => item.key === 'registered')?.value ?? false,
                        showDayCountForGoalDateAs:
                            ev.data.find((item: any) => item.key === 'showDayCountForGoalDateAs')?.value ?? 'day0',
                        showOnlyInCurve: ev.data.find((item: any) => item.key === 'showOnlyInCurve')?.value ?? false,
                        task: ev.data.find((item: any) => item.key === 'task')?.value ?? false,
                        title: ev.data.find((item: any) => item.key === 'title')?.value ?? '',
                    };

                    let vcTherapy: ViewContent<Therapy> = {} as ViewContent<Therapy>;

                    if (method === 'ADD') {
                        vcTherapy = getNewViewContent({
                            locator: `case.overview.therapy.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: therapy,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcTherapy);
                        // await this.api.postViewContent(vcTherapy, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcTherapy = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: therapy,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcTherapy);
                        // await this.api.putViewContent(vcTherapy, this.token.access_token);
                    }
                    break;

                case 'consultations':
                    const consultation: Consultation = {
                        title: ev.data.find((item: any) => item.key === 'title')?.value ?? '',
                        details: ev.data.find((item: any) => item.key === 'details')?.value ?? '',
                        task: ev.data.find((item: any) => item.key === 'task')?.value ?? false,
                        editor: this.user,
                        registered: ev.data.find((item: any) => item.key === 'registered')?.value ?? false,
                        performed: ev.data.find((item: any) => item.key === 'performed')?.value ?? false,
                        goalDateOn: ev.data.find((item: any) => item.key === 'goalDateOn')?.value ?? '',
                        goal_date: ev.data.find((item: any) => item.key === 'goal_date')?.value ?? '',
                        dayCountForGoalDateShowAs:
                            ev.data.find((item: any) => item.key === 'dayCountForGoalDateShowAs')?.value ?? '',
                        dayCountDeactivate: ev.data.find((item: any) => item.key === 'dayCountDeactivate')?.value ?? '',
                        dayCountDeactivateOn:
                            ev.data.find((item: any) => item.key === 'dayCountDeactivateOn')?.value ?? '',
                        showOnlyInCurve: ev.data.find((item: any) => item.key === 'showOnlyInCurve')?.value ?? false,
                    };

                    let vcConsultation: ViewContent<Consultation> = {} as ViewContent<Consultation>;

                    if (method === 'ADD') {
                        vcConsultation = getNewViewContent({
                            locator: `case.overview.consultation.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: consultation,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcConsultation);
                        // await this.api.postViewContent(vcConsultation, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcConsultation = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: consultation,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcConsultation);
                        // await this.api.putViewContent(vcConsultation, this.token.access_token);
                    }

                    break;

                case 'pathogen':
                    const pathogen: Pathogen = {
                        date: new Date().toISOString(),
                        name: ev.data.find((item: any) => item.key === 'name')?.value ?? '',
                        type: ev.data.find((item: any) => item.key === 'type')?.value ?? '',
                    };
                    let vcPathogen: ViewContent<Pathogen> = {} as ViewContent<Pathogen>;
                    if (method === 'ADD') {
                        vcPathogen = getNewViewContent({
                            locator: `case.overview.pathogen.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: pathogen,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcPathogen);
                        // await this.api.postViewContent(vcPathogen, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcPathogen = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: pathogen,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcPathogen);
                        // await this.api.putViewContent(vcPathogen, this.token.access_token);
                    }
                    break;

                case 'discharge':
                    const discharge: Discharge = {
                        comment: ev.data.find((item: any) => item.key === 'comment')?.value ?? '',
                        dismissalExpectedOn:
                            ev.data.find((item: any) => item.key === 'dismissalExpectedOn')?.value ?? '',
                        setInCheckpad: ev.data.find((item: any) => item.key === 'setInCheckpad')?.value ?? false,
                    };

                    let vcDischarge: ViewContent<Discharge> = {} as ViewContent<Discharge>;

                    if (method == 'ADD') {
                        vcDischarge = getNewViewContent({
                            locator: `case.overview.discharge.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: discharge,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcDischarge);
                        // await this.api.postViewContent(vcDischarge, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcDischarge = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: discharge,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcDischarge);
                        // await this.api.putViewContent(vcDischarge, this.token.access_token);
                    }
                    break;

                case 'postTreatment':
                    const postTreatment: Post_Treatment = {
                        postTreatment: ev.data.find((item: any) => item.key === 'postTreatment')?.value ?? '',
                    };

                    let vcDPostTreatment: ViewContent<Post_Treatment> = {} as ViewContent<Post_Treatment>;

                    if (method === 'ADD') {
                        vcDPostTreatment = getNewViewContent({
                            locator: `case.overview.course.${this.caseId}.${uuidv4()}`,
                            related_patient_id: pid,
                            related_case_id: cid,
                            data: postTreatment,
                            id: -1,
                            owners: [],
                            owner_departments: [],
                            main_owner_job_type: 'other',
                            created_at: dayjs().toISOString(),
                            status: 'final',
                            i18n,
                        });
                        await this.vcCache.saveViewContent(vcDPostTreatment);
                        // await this.api.postViewContent(vcDPostTreatment, this.token.access_token);
                    } else if (method === 'UPDATE') {
                        vcDPostTreatment = getNewViewContent({
                            locator: ev.vc.locator,
                            related_patient_id: ev.vc.related_patient_id,
                            related_case_id: ev.vc.related_case_id,
                            data: postTreatment,
                            id: ev.vc.id,
                            owners: ev.vc.owners,
                            owner_departments: ev.vc.owner_departments,
                            main_owner_job_type: ev.vc.main_owner_job_type,
                            created_at: ev.vc.created_at,
                            status: ev.vc.status,
                            i18n: ev.vc.i18n,
                        });
                        await this.vcCache.saveViewContent(vcDPostTreatment);
                        // await this.api.putViewContent(vcDPostTreatment, this.token.access_token);
                    }
                    break;
            }
        }
        // this.refresh();
    }

    public changeTab($event: MatTabChangeEvent | number): void {
        const newTabIndex = $event instanceof MatTabChangeEvent ? $event.index : $event;

        // Update URL to include new tab index
        const newPath = `/patient-details/${this.caseId}/${newTabIndex}`;
        this.location.replaceState(newPath);

        this.currentTab = newTabIndex;
    }

    public onCommunicationSendMessage($event: VC_CommunicationMessage) {
        this.vcCache.saveViewContent($event);
    }

    public async onViewContentChange(vc: ViewContent<unknown>): Promise<void> {
        await this.vcCache.saveViewContent(vc);
    }

    //#endregion

    private async refresh(): Promise<void> {
        if (!this.caseId) throw new Error('Error refreshing view: no patientId found');
        if (!this.token) throw new Error('Error refreshing view: no token available');

        await this.toolbox.executeInLoading(async () => {
            this.vcPatientDetails = (
                await this.api.getVcCaseListItem(this.caseId!, this.token!.access_token)
            ).patient_details;
        });

        const [allLocators, caseDetails] = await Promise.all([
            this.vcCache.getAllLocatorsForCaseId(this.caseId),
            this.api.getVcCaseDetails(this.caseId, this.token.access_token),
        ]);

        this.allLocators = allLocators;
        this.caseDetails = caseDetails;

        this.loadingOverview = true;
        this.loadingCurve = true;
        this.loadingRecord = true;
        this.loadingCommunication = true;
        await Promise.all([
            this.loadPatientRecords().finally(() => (this.loadingRecord = false)),
            this.loadWorkflow().finally(() => (this.loadingWorkflow = false)),
            this.loadOverview().finally(() => (this.loadingOverview = false)),
            this.loadCommunicationMessages().finally(() => (this.loadingCommunication = false)),
            this.loadCurveViewContent().finally(() => (this.loadingCurve = false)),
        ]);

        if (this.vcPatientDetails) {
            const { name, surname, birth_date, gender } = this.vcPatientDetails.patient.data;
            const { room } = this.vcPatientDetails.current_place.data;
            const { service_unit } = room || {};

            this.patientNameAndDob = {
                firstName: name,
                lastName: surname,
                dob: birth_date,
                gender: gender,
                ward: this.vcPatientDetails.current_place?.data?.room?.service_unit?.department?.[0]?.code,
                bed: this.vcPatientDetails.current_place.data.room?.service_unit.code,
                room: this.vcPatientDetails.current_place.data.room?.code,
            };
        }
    }

    private async loadWorkflow(): Promise<void> {
        const locatorPrefixes = ['case.communication.tasks'];

        const allVcs = await Promise.all(
            locatorPrefixes.map((prefix) => {
                const typeLocators = this.allLocators?.filter((loc) => loc?.startsWith(`${prefix}.${this.caseId}`));

                if (typeLocators?.length > 0) {
                    return Promise.all(
                        typeLocators.map((locator) => this.vcCache.getViewContentForLocator(locator).catch(() => null))
                    );
                }

                return Promise.resolve([null]);
            })
        );
    }

    private async loadCurveViewContent(): Promise<void> {
        if (!this.caseId) throw Error('Error loading curve viewContents: this.vcCaseOverview.case unavailable');
        if (!this.token) throw Error('Error loading curve viewContents: no token available');

        const curveLocators = this.allLocators.filter((e) => e.startsWith('case.curve.group'));

        const vccg = await Promise.all(curveLocators.map((e) => this.vcCache.getViewContentForLocator<CurveGroup>(e)));
        this.vc_CurveGroups = [];
        vccg.forEach((e) => {
            if (e) this.vc_CurveGroups.push(e);
        });
        this.vc_CurveGroups = [...this.vc_CurveGroups];

        if (!this.vc_CurveGroups?.length) {
            this.vc_CurveGroups = this.getEmptyVcCurve();
            for (const vc of this.vc_CurveGroups) {
                if (this.user) await this.vcCache.saveViewContent(vc);
            }
        }
    }

    private getEmptyVcCurve(): VC_CurveGroup[] {
        const cid = this.caseDetails?.related_case_id;
        const pid = this.caseDetails?.related_patient_id;

        if (!cid || !pid) {
            console.error('Error creating VC_CurveGroup');
            return [];
        }

        // Upper part
        const notesRow = getNewCurveRow(uuidv4(), 'Notizen');
        const appointmentsRow = getNewCurveRow(uuidv4(), 'Termine');
        const diagnosticRow = getNewCurveRow(uuidv4(), 'Diagnostik');
        const therapyRow = getNewCurveRow(uuidv4(), 'Therapie');
        const basisGroup = getNewCurveGroup(uuidv4(), 'Basisinformationen', 'upper', [
            notesRow,
            appointmentsRow,
            diagnosticRow,
            therapyRow,
        ]);

        // Middle part
        const heartRate = getNewCurveRow(uuidv4(), 'Herzfrequenz');
        const bloodPressure = getNewCurveRow(uuidv4(), 'Blutdruck');
        const temperature = getNewCurveRow(uuidv4(), 'Temperatur');
        const respiratoryRate = getNewCurveRow(uuidv4(), 'Atemfrequenz');
        const vitalSigns = getNewCurveGroup('vital_signs-' + uuidv4(), 'Vitalwerte', 'middle', [
            heartRate,
            bloodPressure,
            temperature,
            respiratoryRate,
        ]);

        // Lower part
        const accessGroup = getNewCurveGroup(uuidv4(), 'Zugänge/Dränage/Bilanz', 'lower');
        const medicationGroup = getNewCurveGroup(uuidv4(), 'Medikation', 'lower');

        const i18n: FormioRendererI18n = {
            de: {
                Eingabe: 'Eingabe',
                Speichern: 'Speichern',
                Abbrechen: 'Abbrechen',
                'Field Set': 'Field Set',
            },
            en: {
                Eingabe: 'Input',
                Speichern: 'Save',
                Abbrechen: 'Cancel',
                'Field Set': 'Field Set',
            },
        };

        const basisVc = getNewViewContent({
            locator: 'case.curve.group.basisGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: basisGroup,
            id: -1,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const vitalSignsVc = getNewViewContent({
            locator: 'case.curve.group.vitalSigns-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: vitalSigns,
            id: -1,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const medicationVc = getNewViewContent({
            locator: 'case.curve.group.medicationGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: medicationGroup,
            id: -1,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        const accessVc = getNewViewContent({
            locator: 'case.curve.group.accessGroup-' + this.caseId,
            related_patient_id: pid,
            related_case_id: cid,
            data: accessGroup,
            id: -1,
            owners: [],
            owner_departments: [],
            main_owner_job_type: 'other',
            created_at: dayjs().toISOString(),
            status: 'not_final',
            i18n,
        });

        return [basisVc, vitalSignsVc, accessVc, medicationVc];
    }

    private async processIncomingVc(vc: ViewContent<any>): Promise<void> {
        const l = vc.locator;

        if (l.startsWith('case.curve.group')) {
            const index = this.vc_CurveGroups.findIndex((e) => e.locator == l);
            const id = this.vc_CurveGroups[index].id; // Get the original id to prevent using this from API
            this.vc_CurveGroups.splice(index, 1, { ...(vc as VC_CurveGroup), id });
            this.vc_CurveGroups = JSON.parse(JSON.stringify(this.vc_CurveGroups));
        }
        // Commented on 14.01.2025 because 'case.overview.tasks' should not be in use anymore.
        // Use instead 'case.communication.tasks'
        // if (l.startsWith('case.overview.tasks')) {
        //     if (this.vcCaseOverview) {
        //         if (this.vcCaseOverview.task.length > 0) {
        //             const index = this.vcCaseOverview.task.findIndex((e) => e.locator == l);
        //             if (index !== -1) {
        //                 this.vcCaseOverview.task.splice(index, 1, vc as VC_Task);
        //             } else {
        //                 this.vcCaseOverview.task.push(vc as VC_Task);
        //             }
        //             this.vcCaseOverview = JSON.parse(JSON.stringify(this.vcCaseOverview));
        //         } else {
        //             this.vcCaseOverview.task = [vc];
        //         }
        //     }
        // }
        if (l.startsWith('case.overview.diagnostic')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.diagnostic.length > 0) {
                    const index = this.vcCaseOverview.diagnostic.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.diagnostic.splice(index, 1, vc as VC_Diagnostic);
                    } else {
                        this.vcCaseOverview.diagnostic = [...this.vcCaseOverview.diagnostic, vc as VC_Diagnostic];
                    }
                    this.vcCaseOverview.diagnostic = JSON.parse(JSON.stringify(this.vcCaseOverview.diagnostic));
                } else {
                    this.vcCaseOverview.diagnostic = [vc];
                }
            }
        }
        if (l.startsWith('case.overview.anamnesis')) {
            if (this.vcCaseOverview) {
                this.vcCaseOverview.medical_history = vc as VC_Medical_History;
                this.vcCaseOverview.medical_history = JSON.parse(JSON.stringify(this.vcCaseOverview.medical_history));
            }
        }
        if (l.startsWith('case.overview.diagnosis')) {
            if (this.vcCaseOverview) {
                this.vcCaseOverview.diagnose = vc as VC_Diagnose;
                this.vcCaseOverview.diagnose = JSON.parse(JSON.stringify(this.vcCaseOverview.diagnose));
            }
        }
        if (l.startsWith('case.overview.therapy')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.therapy.length > 0) {
                    const index = this.vcCaseOverview.therapy.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.therapy.splice(index, 1, vc as VC_Therapy);
                    } else {
                        this.vcCaseOverview.therapy = [...this.vcCaseOverview.therapy, vc as VC_Therapy];
                    }
                    this.vcCaseOverview.therapy = JSON.parse(JSON.stringify(this.vcCaseOverview.therapy));
                } else {
                    this.vcCaseOverview.therapy = [vc];
                }
            }
        }
        if (l.startsWith('case.overview.consultation')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.consultation.length > 0) {
                    const index = this.vcCaseOverview.consultation.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.consultation.splice(index, 1, vc as VC_Consultation);
                    }
                    this.vcCaseOverview.consultation = JSON.parse(JSON.stringify(this.vcCaseOverview.consultation));
                } else {
                    this.vcCaseOverview.consultation = [vc];
                }
            }
        }
        if (l.startsWith('case.overview.pathogen')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.pathogen.length > 0) {
                    const index = this.vcCaseOverview.pathogen.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.pathogen.splice(index, 1, vc as VC_Pathogen);
                    }
                    this.vcCaseOverview.pathogen = JSON.parse(JSON.stringify(this.vcCaseOverview.pathogen));
                } else {
                    this.vcCaseOverview.pathogen = [vc];
                }
            }
        }
        if (l.startsWith('case.overview.discharge')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.discharge.length > 0) {
                    const index = this.vcCaseOverview.discharge.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.discharge.splice(index, 1, vc as VC_Discharge);
                    }
                    this.vcCaseOverview.discharge = JSON.parse(JSON.stringify(this.vcCaseOverview.discharge));
                } else {
                    this.vcCaseOverview.discharge = [vc];
                }
            }
        }
        if (l.startsWith('case.overview.course')) {
            if (this.vcCaseOverview) {
                if (this.vcCaseOverview.post_treatment.length > 0) {
                    const index = this.vcCaseOverview.post_treatment.findIndex((e) => e.locator == l);
                    if (index !== -1) {
                        this.vcCaseOverview.post_treatment.splice(index, 1, vc as VC_Post_Treatment);
                    }
                    this.vcCaseOverview.post_treatment = JSON.parse(JSON.stringify(this.vcCaseOverview.post_treatment));
                } else {
                    this.vcCaseOverview.post_treatment = [vc];
                }
            }
        }
        if (l.startsWith('document.')) {
            // Initialisiere vcFindings als leeres Array, falls nicht vorhanden
            if (!Array.isArray(this.vcFindings)) {
                this.vcFindings = [];
            }

            const existingIndex = this.vcFindings.findIndex((f: ViewContent<PatientRecordVCData>) => {
                return f.locator === vc.locator;
            });

            if (vc.status === 'archived') {
                if (existingIndex !== -1) {
                    this.vcFindings.splice(existingIndex, 1);
                    this.vcFindings = [...this.vcFindings];
                }
            } else {
                if (existingIndex !== -1) {
                    this.vcFindings[existingIndex] = vc;
                    this.vcFindings = [...this.vcFindings];
                } else {
                    this.vcFindings = [...this.vcFindings, vc];
                }
            }

            this.patientRecordData = {
                finding: this.vcFindings,
                labor: this.laborVc,
                record: this.records,
                regions: this.regions,
                subRegion: this.subRegions,
                pid: this.caseDetails?.related_patient_id ?? '-1',
                cid: this.caseDetails?.related_case_id ?? '-1',
            };
        }
        if (l.startsWith('case.communication.tasks')) {
            if (this.vc_CommunicationMessages) {
                const index = this.vc_CommunicationMessages.findIndex((m) => m.locator === l);
                if (index >= 0) {
                    const id = this.vc_CommunicationMessages[index].id;
                    this.vc_CommunicationMessages.splice(index, 1, { ...(vc as VC_CommunicationMessage), id });
                } else {
                    this.vc_CommunicationMessages = [...this.vc_CommunicationMessages, vc as VC_CommunicationMessage];
                }
            }
            if (this.vcCaseOverview) {
                this.vcCaseOverview.task = this.vc_CommunicationMessages;
            }
        }
        if (l.startsWith('labor.results')) {
            if (this.vcLabor) {
                // Aktualisiere vcLabor und erstelle eine neue Referenz, um Angular die Änderung mitzuteilen
                this.vcLabor = { ...vc } as ViewContent<LabReport>;

                // Aktualisiere die Laborergebnisse
                this.updateLaborResults(this.vcLabor);
            }
        }
    }

    private async loadCommunicationMessages(): Promise<void> {
        const communicationLocators = this.allLocators.filter((l) => l.startsWith('case.communication.task'));
        const messages: VC_CommunicationMessage[] = [];
        const commPromises = communicationLocators.map(async (e) => {
            const vc = await this.vcCache.getViewContentForLocator<CommunicationMessage>(e);
            if (vc) messages.push(vc);
        });
        await Promise.all(commPromises);
        this.vc_CommunicationMessages = messages;
    }

    private async loadOverview(): Promise<void> {
        const locatorPrefixes = [
            'case.overview.anamnesis',
            'case.overview.diagnosis',
            'case.overview.therapy',
            'case.overview.course',
            'case.overview.diagnostic',
            'case.overview.consultation',
            'case.overview.pathogen',
            'case.overview.discharge',
            'case.communication.tasks',
            'case.overview.drg',
        ];

        const contentData = await Promise.all(
            locatorPrefixes.map((prefix) => {
                const typeLocators = this.allLocators?.filter((loc) => loc?.startsWith(`${prefix}.${this.caseId}`));

                if (typeLocators?.length > 0) {
                    return Promise.all(
                        typeLocators.map(
                            (locator) => this.vcCache.getViewContentForLocator(locator).catch(() => null) // Catch errors and replace with null
                        )
                    );
                }

                return Promise.resolve([null]);
            })
        );

        const getData = <T>(responseArray: (ViewContent<T> | null)[]): ViewContent<T> | null => {
            return responseArray && responseArray.length > 0 ? responseArray[0] : null;
        };

        const getAllData = <T>(responseArray: (ViewContent<T> | null)[]): ViewContent<T>[] => {
            return responseArray?.filter((response) => response !== null) as ViewContent<T>[];
        };

        const [
            anamnesis,
            diagnosis,
            therapy,
            course,
            diagnostic,
            consultation,
            pathogen,
            discharge,
            tasks,
            postTreatment,
        ] = contentData.map((item: any) => item || [null]);

        if (this.vcPatientDetails) {
            this.vcCaseOverview = {
                case: this.vcPatientDetails.case,
                medical_history: getData(anamnesis) as ViewContent<Medical_History>,
                diagnose: getData(diagnosis) as ViewContent<Diagnose>,
                therapy: getAllData(therapy) as ViewContent<Therapy>[],
                visit_record: {} as ViewContent<Visit_Record>,
                diagnostic: getAllData(diagnostic) as ViewContent<Diagnostic>[],
                consultation: getAllData(consultation) as ViewContent<Consultation>[],
                task: getAllData(tasks) as ViewContent<CommunicationMessage>[],
                discharge: getAllData(discharge) as ViewContent<Discharge>[],
                pathogen: getAllData(pathogen) as ViewContent<Pathogen>[],
                post_treatment: getAllData(course) as ViewContent<Post_Treatment>[],
            };
        }
    }

    private async loadPatientRecords() {
        const access_token = this.token?.access_token;
        if (access_token) {
            try {
                let [dicomsOffline, dicomsOnline, documents, labor] = await Promise.all([
                    this.vcCache.getViewContentBatchForLocator<PatientRecordVCData>(
                        `document.dicom.offline.${this.caseId}`
                    ),
                    this.vcCache.getViewContentBatchForLocator<PatientRecordVCData>(
                        `document.dicom.online.${this.caseId}`
                    ),
                    this.vcCache.getViewContentBatchForLocator<PatientRecordVCData>(`document.others.${this.caseId}`),
                    this.vcCache.getViewContentBatchForLocator<LabReport>(`labor.results.${this.caseId}`),
                ]);

                dicomsOffline = dicomsOffline?.filter((f) => {
                    return f.status !== 'archived';
                });

                dicomsOnline = dicomsOnline?.filter((f) => {
                    return f.status !== 'archived';
                });

                documents = documents?.filter((f) => {
                    return f.status !== 'archived';
                });

                labor = labor?.filter((f) => {
                    return f.status !== 'archived';
                });

                if (documents) this.updateContentSets(documents);
                if (dicomsOffline) this.updateContentSets(dicomsOffline);
                if (dicomsOnline) this.updateContentSets(dicomsOnline);
                if (labor) labor.forEach((r) => this.updateLaborResults(r));

                this.vcFindings = [...(documents ?? []), ...(dicomsOffline ?? []), ...(dicomsOnline ?? [])];

                this.patientRecordData = {
                    finding: this.vcFindings,
                    labor: this.laborVc,
                    record: this.records,
                    regions: this.regions,
                    subRegion: this.subRegions,
                    pid: this.caseDetails?.related_patient_id ?? '-1',
                    cid: this.caseDetails?.related_case_id ?? '-1',
                };
            } catch (error) {
                console.error('Error fetching patient findings:', error);
            }
        }
    }

    private updateContentSets(contents: ViewContent<PatientRecordVCData>[]) {
        contents.forEach((r) => {
            if (r.locator.startsWith('document.') && r.status !== 'archived') {
                // Aktualisiere Area, falls nicht vorhanden
                if (!this.areaSet.has(String(r.data?.area?.id)) && r.data?.area) {
                    this.areaSet.add(String(r.data?.area?.id));
                    this.regions.push(r.data?.area);
                }

                // Aktualisiere SubArea, falls nicht vorhanden
                if (!this.subAreaSet.has(String(r.data?.subarea?.id))) {
                    this.subAreaSet.add(String(r.data?.subarea?.id));
                    this.subRegions.push(r.data?.subarea);
                }

                // Aktualisiere Record, falls nicht vorhanden
                if (!this.recordSet.has(r.data?.record?.name) && r.data?.record) {
                    this.recordSet.add(r.data?.record?.name);
                    this.records.push(r.data?.record);
                }
            }
        });
    }

    private updateLaborResults(r: ViewContent<LabReport>) {
        if (r.locator.startsWith('labor.results') && r.status !== 'archived') {
            const labor: any = r;

            labor.data.results.forEach((result: any) => {
                if (!this.areaSet.has(String(result?.area?.id))) {
                    this.areaSet.add(String(result?.area?.id));
                    this.regions.push(result?.area);
                }

                if (!this.recordSet.has(result?.record?.name)) {
                    this.recordSet.add(result?.record?.name);
                    this.records.push(
                        result?.record ?? {
                            name: 'Sonstiges',
                            id: '-1',
                        }
                    );
                }
            });

            // Direktes Setzen von laborVc auf labor, ohne push
            Object.assign(this.laborVc, labor);
        }
    }
}
