import { Inject, Injectable } from '@angular/core';
import * as cornerstone3d from '@cornerstonejs/core';
import { getRenderingEngine, init as csRenderInit, Types } from '@cornerstonejs/core';
import {
    addTool,
    destroy,
    Enums as csToolsEnums,
    init as csToolsInit,
    PanTool,
    StackScrollMouseWheelTool,
    ToolGroupManager,
    WindowLevelTool,
    ZoomTool,
} from '@cornerstonejs/tools';
import dicomParser from 'dicom-parser';
import { MetadataService } from './metadata.service';
import html2canvas from 'html2canvas';

declare const cornerstoneDICOMImageLoader: any;

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    static instanceId = 1;
    renderingEngine: any;
    renderingEngineId = 'renderId_' + ImageService.instanceId;

    constructor(
        @Inject(String) private viewportId: string,
        @Inject(Boolean) private metaDataService: MetadataService
    ) {
        ImageService.instanceId++;
    }

    async initCornerstoneDICOMImageLoader() {
        const { preferSizeOverAccuracy, useNorm16Texture } = cornerstone3d.getConfiguration().rendering;

        cornerstoneDICOMImageLoader.external.cornerstone = cornerstone3d;
        cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

        (window as any).cornerstone3d = cornerstone3d;

        cornerstoneDICOMImageLoader.configure({
            useWebWorkers: true,
            decodeConfig: {
                convertFloatPixelDataToInt: false,
                use16BitDataType: preferSizeOverAccuracy || useNorm16Texture,
            },
        });

        const maxWebWorkers = navigator.hardwareConcurrency ? Math.min(navigator.hardwareConcurrency, 7) : 1;

        const config = {
            maxWebWorkers,
            startWebWorkersOnDemand: false,
            taskConfiguration: {
                decodeTask: {
                    initializeCodecsOnStartup: false,
                    strict: false,
                },
            },
        };

        cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

        csToolsInit();
        await csRenderInit();
        this.renderingEngine = new cornerstone3d.RenderingEngine(this.renderingEngineId);

        const toolGroupId = 'myToolGroup';
        var toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
        if (!toolGroup) {
            toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
        }

        if (toolGroup) {
            if (!toolGroup.hasTool(PanTool.toolName)) {
                addTool(PanTool);
                toolGroup.addTool(PanTool.toolName);
            }
            if (!toolGroup.hasTool(WindowLevelTool.toolName)) {
                addTool(WindowLevelTool);
                toolGroup.addTool(WindowLevelTool.toolName);
            }
            if (!toolGroup.hasTool(StackScrollMouseWheelTool.toolName)) {
                addTool(StackScrollMouseWheelTool);
                toolGroup.addTool(StackScrollMouseWheelTool.toolName);
            }
            if (!toolGroup.hasTool(ZoomTool.toolName)) {
                addTool(ZoomTool);
                toolGroup.addTool(ZoomTool.toolName);
            }

            toolGroup.addViewport(this.viewportId, this.renderingEngineId);

            // Set the initial state of the tools, here all tools are active and bound to
            // Different mouse inputs
            // Helligkeit & Kontrast
            toolGroup.setToolActive(WindowLevelTool.toolName, {
                bindings: [
                    {
                        mouseButton: csToolsEnums.MouseBindings.Primary, // Left Click
                    },
                ],
            });
            // Lässt die DICOM verschieben (gedrückt halten)
            toolGroup.setToolActive(PanTool.toolName, {
                bindings: [
                    {
                        mouseButton: csToolsEnums.MouseBindings.Auxiliary, // Middle Click
                    },
                ],
            });
            // ZoomIn & ZoomOut (hoch | runter)
            toolGroup.setToolActive(ZoomTool.toolName, {
                bindings: [
                    {
                        mouseButton: csToolsEnums.MouseBindings.Secondary, // Right Click
                    },
                ],
            });

            // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
            // hook instead of mouse buttons, it does not need to assign any mouse button.
            toolGroup.setToolActive(StackScrollMouseWheelTool.toolName);
        }
    }

    loadDataSetFromURL(url: any) {
        return cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(
            url,
            cornerstoneDICOMImageLoader.internal.xhrRequest
        );
    }

    loadImage(imageId: any) {
        return cornerstoneDICOMImageLoader.wadouri.loadImage(imageId).promise;
    }

    getImageIdFromFile(file: any) {
        return cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
    }

    async loadImageOnViewport(stack: any) {
        const renderingEngine = this.renderingEngine;

        const element = document.getElementById(this.viewportId) as HTMLDivElement;

        if (element) {
            // Disable the default context menu
            element.oncontextmenu = (e) => e.preventDefault();

            const viewportInput: any = {
                viewportId: this.viewportId,
                type: cornerstone3d.Enums.ViewportType.STACK,
                element,
                defaultOptions: {
                    background: <cornerstone3d.Types.Point3>[0, 0, 0],
                },
            };

            const enabledElement: any = cornerstone3d.getEnabledElement(element);

            if (!enabledElement && renderingEngine) {
                renderingEngine.enableElement(viewportInput);
            }

            const viewport = <cornerstone3d.Types.IStackViewport>renderingEngine.getViewport(this.viewportId);

            await viewport.setStack(stack).then(() => {
                viewport.resetProperties();
                viewport.resetCamera(true, true);

                viewport.render();
            });
        }
    }

    public onImageRendered(e: any) {
        const eventData = e.detail;
        console.log('################################');
        console.log(eventData);
    }

    public zoomIn() {
        const viewport = <Types.IVolumeViewport>this.getViewport();

        const zoom = viewport.getZoom();

        viewport.setZoom(zoom * 1.05);
        viewport.render();
    }

    public zoomOut() {
        const viewport = <Types.IVolumeViewport>this.getViewport();

        const zoom = viewport.getZoom();

        viewport.setZoom(zoom * 0.95);
        viewport.render();
    }

    public resetZoom() {
        // Get the stack viewport
        const viewport = <Types.IVolumeViewport>this.getViewport();

        viewport.resetCamera(false, true, false);
        viewport.render();
    }

    public nextImage() {
        // Get the stack viewport
        const viewport = <Types.IStackViewport>this.getViewport();

        // Get the current index of the image displayed
        const currentImageIdIndex = viewport.getCurrentImageIdIndex();

        // Increment the index, clamping to the last image if necessary
        const numImages = viewport.getImageIds().length;
        let newImageIdIndex = currentImageIdIndex + 1;

        newImageIdIndex = Math.min(newImageIdIndex, numImages - 1);

        // Set the new image index, the viewport itself does a re-render
        viewport.setImageIdIndex(newImageIdIndex);
    }

    public previousImage() {
        const viewport = <Types.IStackViewport>this.getViewport();

        // Get the current index of the image displayed
        const currentImageIdIndex = viewport.getCurrentImageIdIndex();

        // Increment the index, clamping to the first image if necessary
        let newImageIdIndex = currentImageIdIndex - 1;

        newImageIdIndex = Math.max(newImageIdIndex, 0);

        // Set the new image index, the viewport itself does a re-render
        viewport.setImageIdIndex(newImageIdIndex);
    }

    public async screenShot(): Promise<string | null> {
        if (!this.metaDataService?.viewMetaData) {
            const element = document.getElementById(this.viewportId) as HTMLDivElement;
            const canvas = element.querySelector('canvas') as HTMLCanvasElement;
            if (!canvas) {
                console.error('Canvas-Element nicht gefunden');
                return null;
            }

            const base64Image = canvas.toDataURL('image/png');

            if (base64Image) {
                const img = new Image();
                img.src = base64Image;
                document.body.appendChild(img);

                return base64Image;
            } else {
                console.error('Base64-Bild konnte nicht erstellt werden');
                return null;
            }
        } else {
            const element = document.getElementById(this.viewportId) as HTMLDivElement;
            const viewport = element.getElementsByClassName('viewport-element')[0] as HTMLElement;
            const wrapper = viewport.getElementsByClassName('metadata-wrapper')[0] as HTMLElement;

            if (wrapper) {
                const canvas = await html2canvas(wrapper);
                const base64Image = canvas.toDataURL('image/png');

                if (base64Image) {
                    const img = new Image();
                    img.src = base64Image;
                    document.body.appendChild(img);

                    return base64Image;
                } else {
                    console.error('Base64-Bild konnte nicht erstellt werden');
                    return null;
                }
            } else {
                console.error('Wrapper-Element nicht gefunden');
            }
        }

        return null;
    }

    public clean() {
        destroy();
    }

    private getViewport() {
        const renderingEngine = getRenderingEngine(this.renderingEngineId);

        return renderingEngine?.getViewport(this.viewportId);
    }
}
