<div [ngClass]="{ 'border-b-2': !last }" class="change-history-container pt-[.3em] pb-[.3em] mb-[.5em]">
    <div>
        <div class="font-light">
            <!-- {{
                'COMPONENT.CHANGES_HISTORY.label_date_time_and_editor'
                    | translate
                        : {
                              date_and_time: modifiedAt | date: ('GENERAL.date_time_format' | translate),
                              editor: modifiedBy,
                          }
            }} -->
            {{ 'COMPONENT.CHANGES_HISTORY.text_changes_at' | translate }}
            <span class="font-normal">{{ modifiedAt | date: ('GENERAL.date_time_format' | translate) }}</span>
            {{ 'COMPONENT.CHANGES_HISTORY.text_by' | translate }}
            <span class="font-normal">{{ modifiedBy }}</span>
        </div>

        <table>
            <ng-container *ngFor="let diff of differences">
                <tr *ngIf="!hideFields?.includes(diff.key)">
                    <td class="align-top pr-[.5em]">
                        <div class="font-light">{{ diff.key }}: </div>
                    </td>
                    <td>
                        <span
                            *ngIf="
                                showOldValues &&
                                diff.newValue != undefined &&
                                form_file_name !== 'form_anamnesis.json' &&
                                form_file_name !== 'form_diagnoses.json' &&
                                form_file_name !== 'form_visit.json'
                            "
                            class="table-text changed-content font-normal"
                        >
                            {{ diff.newValue }}
                        </span>
                        <span class="table-text history">{{ diff.oldValue }}</span>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- Restore button -->
    <mat-icon
        (click)="openDialog()"
        *ngIf="index !== 0"
        class="restore-history-button hover:cursor-pointer"
        mat-mini-fab
    >
        settings_backup_restore
    </mat-icon>
</div>
