<h2 mat-dialog-title *ngIf="data.header">{{ data.header }}</h2>

<mat-dialog-content *ngIf="data.message">
    {{ data.message }}

    <mat-form-field *ngFor="let input of data.inputs">
        <mat-label>{{ input.label }}</mat-label>
        <input
            matInput
            [placeholder]="input.placeholder ?? ''"
            [value]="input.value ?? null"
            (change)="onInputChange(input.name, $event)"
        />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions *ngIf="data.buttons?.length">
    <button *ngFor="let b of data.buttons" mat-button (click)="onClickOnButton(b)">{{ b.text }}</button>
</mat-dialog-actions>
