<p *ngIf="vcPatientDetails && !hideCurrentPlace" class="text-gray-600 font-extralight">
    <app-breadcrumbs
        (clickOnBreadcrumb)="onClickOnBreadcrumb($event)"
        [place]="vcPatientDetails.current_place"
    ></app-breadcrumbs>
</p>

<div *ngIf="vcPatientDetails" class="flex flex-col">
    <div class="flex flex-col">
        <div class="flex flex-row flex-wrap gap-1 items-center">
            <!-- Firstname and Surname -->
            <div class="font-bold">
                {{ vcPatientDetails?.patient?.data?.surname ?? '' }},
                {{ vcPatientDetails?.patient?.data?.name ?? '' }}
            </div>

            <!-- Gender -->
            <div>
                {{ 'GENERAL.' + (vcPatientDetails?.patient?.data?.gender ?? '') | translate }},
            </div>

            <!-- Birthday and Age -->
            <div>
                {{ vcPatientDetails?.patient?.data?.birth_date | date: ('GENERAL.date_format' | translate) }}
                ({{ vcPatientDetails?.patient?.data?.birth_date | calAge }})
            </div>

            <!-- Admission Date -->
            <div>
                A: {{ vcPatientDetails?.case?.data?.admission_date | date: ('GENERAL.date_format' | translate) }}<span *ngIf="vcPatientDetails?.case?.data?.discharge_date">,</span>
            </div>

            <!-- Discharge Date -->
            <ng-container *ngIf="vcPatientDetails?.case?.data?.discharge_date">
                <div>
                    {{ 'COMPONENT.PAGE_PATIENT_LIST.planned_discharge_date' | translate }}:
                    {{ vcPatientDetails?.case?.data?.discharge_date | date: ('GENERAL.date_format' | translate) }}
                    ({{ calculateHospitalStayDays(vcPatientDetails?.case?.data?.admission_date, vcPatientDetails?.case?.data?.discharge_date) | number: '1.0-0' }})
                </div>
            </ng-container>
        </div>

        <p class="font-extralight diagnosis-title">
            {{ vcPatientDetails?.last_diagnosis?.data?.diagnose ?? '' }}
        </p>
    </div>
</div>
