<div class="mat-elevation-z8">
    <table mat-table [dataSource]="tasks" class="mat-elevation-z8">
        <!-- Task Name Column -->
        <ng-container matColumnDef="task_name">
            <th mat-header-cell *matHeaderCellDef>Aufgabe</th>
            <td mat-cell *matCellDef="let task">{{ task.data.task_name }}</td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="details">
            <th mat-header-cell class="grow" *matHeaderCellDef>Details</th>
            <td mat-cell *matCellDef="let task" class="grow">{{ task.data.details }}</td>
        </ng-container>

        <!-- Goal Date Column -->
        <ng-container matColumnDef="goalDateOn">
            <th mat-header-cell *matHeaderCellDef>Ziel Datum</th>
            <td mat-cell *matCellDef="let task">{{ task.data.goalDateOn | date: 'dd.MM.yy' }}</td>
        </ng-container>

        <!-- Contractor -->
        <ng-container matColumnDef="contractor">
            <th mat-header-cell *matHeaderCellDef>Auftragnehmer</th>
            <td mat-cell *matCellDef="let task">{{ task.data.contractor?.name }} {{ task.data.contractor?.surname }}</td>
        </ng-container>

        <!-- Priority -->
        <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef>Priorität</th>
            <td mat-cell *matCellDef="let task">{{ 'GENERAL.priority_' + task.data.priority | translate }}</td>
        </ng-container>

        <!-- Done -->
        <ng-container matColumnDef="done">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let task">
                <mat-icon *ngIf="task.data.done" fontIcon="done"></mat-icon>
                <mat-icon *ngIf="!task.data.done" fontIcon="pending"></mat-icon>
            </td>
        </ng-container>

        <!-- Table Header -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <!-- Table Row -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"></tr>
    </table>
</div>
