import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule, Location } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { navListAnimation } from '../../../shared/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NetworkQualityComponent } from '../../network-quality/network-quality.component';
import { ServerSentEventHandlerService } from '../../../services/mercure/server-sent-event-handler.service';
import { Subscription } from 'rxjs';
import { TooltipService } from '@babybeet/angular-tooltip';
import { DeviceRegisteredIndicatorComponent } from '../../device-registered-indicator/device-registered-indicator.component';
import { Preferences } from '@capacitor/preferences';
import { LS_LOCATORS } from '../../../shared/constants';
import { ViewContentCacheService } from '../../../services/cache/view-content-cache.service';

export type SidebarButton = {
    name: SidebarButtonName;
    label: string;
    disabled: boolean;
    link?: string;
    svg?: string;
    icon_name?: string;
};

export type SidebarButtonName =
    | 'back'
    | 'toggle_menu'
    | 'tasks'
    | 'my_lists'
    | 'global_search'
    | 'refresh_access'
    | 'message'
    | 'settings'
    | 'divider'
    | 'admin'
    | 'chat'
    | 'logout';

const slideInOut = trigger('slideInOut', [
    state('true', style({ width: '250px' })),
    state('false', style({ width: '64px' })),
    transition('false => true', animate('200ms ease-in')),
    transition('true => false', animate('200ms ease-out')),
]);

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        DeviceRegisteredIndicatorComponent,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        NetworkQualityComponent,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [slideInOut, navListAnimation],
})
export class SidebarComponent implements OnInit, OnDestroy {
    /**
     * Emits when a list item (a button) of the sidebar is clicked.
     */
    @Output() clickSidebarButton = new EventEmitter<SidebarButton>();
    @Input() userData: { name: string; photoLink: string } = {
        name: 'Dr. Saki Lobinger',
        photoLink: '../assets/img/placeholder.jpg',
    };
    @Input() currentBreakpoint: string = '';

    @Input() topButtons: SidebarButton[] = [
        {
            name: 'tasks',
            label: 'COMPONENT.SIDEBAR.tasks',
            disabled: true,
            link: '/tasks',
            // svg: '../../../assets/icons/task_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'task',
        },
        {
            name: 'my_lists',
            label: 'COMPONENT.SIDEBAR.my_lists',
            disabled: true,
            link: '/my-lists',
            // svg: '../../../assets/icons/clinical_notes_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'list',
        },
        {
            name: 'chat',
            label: 'COMPONENT.SIDEBAR.chat',
            disabled: true,
            link: '/chat',
            icon_name: 'chat',
        },
        {
            name: 'admin',
            label: 'COMPONENT.SIDEBAR.admin',
            disabled: false,
            link: '/admin',
            icon_name: 'admin_panel_settings',
        },
    ];
    @Input() bottomButtons: SidebarButton[] = [
        // {
        //     name: 'admin',
        //     label: 'COMPONENT.SIDEBAR.admin',
        //     link: '/admin',
        //     svg: '../../../assets/icons/construct-outline.svg',
        // },
        {
            name: 'settings',
            label: 'COMPONENT.SIDEBAR.settings',
            disabled: false,
            link: '/settings',
            icon_name: 'settings',
            // svg: '../../../assets/icons/settings_FILL0_wght400_GRAD0_opsz24.svg',
        },
        {
            name: 'logout',
            label: 'COMPONENT.SIDEBAR.logout',
            disabled: false,
            link: '/logout',
            // svg: '../../../assets/icons/logout_FILL0_wght400_GRAD0_opsz24.svg',
            icon_name: 'logout',
        },
    ];
    /**
     * Flag indicating whether the sidebar is expanded or collapsed.
     */
    @Input() isExpanded: boolean = false;
    @ViewChild('sidenav') sidenav!: MatSidenav;
    public locatorStatus: {
        remaining: number;
        processed: number;
        locatorLog: { locator: string; success: boolean }[];
    } = {
        remaining: 0,
        processed: 0,
        locatorLog: [],
    };
    public toggleButton: SidebarButton = {
        name: 'toggle_menu',
        label: 'COMPONENT.SIDEBAR.menu_minimize',
        disabled: false,
        icon_name: 'keyboard_double_arrow_right',
        // svg: '../../../assets/icons/keyboard_double_arrow_left_FILL0_wght400_GRAD0_opsz24.svg',
    };
    public currentPath: string;
    private isTooltipOpen: boolean = false;
    private tooltipTimeout: any;
    private allSubs: Subscription[] = [];

   public constructor(
        private SSEHandler: ServerSentEventHandlerService,
        private readonly tooltipService: TooltipService,
        private location: Location,
        public vcCache: ViewContentCacheService
    ) {
        this.currentPath = this.location.path();
    }

    public ngOnInit() {
        this.allSubs.push(
            this.SSEHandler.locatorStatus$.subscribe((status) => {
                this.locatorStatus = status;
            })
        );
    }

    public ngOnDestroy() {
        this.allSubs.forEach((s) => s.unsubscribe());
    }

    /**
     * Handles click events on sidebar buttons.
     * @param item - The sidebar button that was clicked.
     */
    onClickOnItem(item: SidebarButton): void {
        this.clickSidebarButton.emit(item);
        this.sidenav.close();
    }

    /**
     * Generates initials from a given name.
     * @param name - The name to generate initials from.
     * @returns A string of initials.
     */
    getInitials(name: string): string {
        return name
            ? name
                  .split(' ')
                  .map((word) => word[0])
                  .join('')
                  .toUpperCase()
            : '';
    }

    public toggleTooltip(event: MouseEvent, templateRefContent: TemplateRef<any>) {
        if (this.isTooltipOpen) {
            this.onHideTooltip();
        } else {
            this.onShowTooltip(event, templateRefContent);
        }

        this.isTooltipOpen = !this.isTooltipOpen;
    }

    public async deleteLocatorQueueeInLS() {
        const delSync = confirm('Möchten Sie die Sync Warteschlange löschen?');
        if (delSync) {
            await Preferences.remove({ key: LS_LOCATORS });
            this.SSEHandler.updateLocatorStatus(
                0,
                0,
                {} as {
                    locator: string;
                    success: boolean;
                },
                true
            );
            this.onHideTooltip();
            this.isTooltipOpen = false;
        }
    }

    /**
     * Hides the tooltip.
     */
    private onHideTooltip(): void {
        clearTimeout(this.tooltipTimeout);
        this.tooltipService.hide();
    }

    /**
     * Shows a tooltip.
     * @param event The pointer event.
     * @param templateRefContent The template reference for the tooltip content.
     */
    private onShowTooltip(event: MouseEvent, templateRefContent: TemplateRef<any>): void {
        const target = event.target as Element | null;
        if (target) {
            this.tooltipService.show(target, {
                content: templateRefContent,
                context: { $implicit: 'TemplateRef' },
                placement: 'vertical',
                theme: 'light',
            });
        }
    }
}
