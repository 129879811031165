import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PatientNameAndDob } from '../../../pages/patient-details/patient-details.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormioRendererI18n } from '../formio-renderer/formio-renderer.component';
import { ConfirmationDialogComponent } from '../../utility/confirmation-dialog/confirmation-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { firstValueFrom } from 'rxjs';
import { VcHistoryElement } from '../../../models/view-content.models/view-content.model';

interface FormModalResult {
    role: 'cancel' | 'restore';
    data: any;
}

interface Difference {
    key: string;
    oldValue: string;
    newValue: string;
}

/**
 * This component is each of the elements of the changing history list of the ViewContents
 */
@Component({
    selector: 'app-changing-history',
    templateUrl: './changing-history.component.html',
    styleUrls: ['./changing-history.component.scss'],
    standalone: true,
    imports: [CommonModule, MatBadgeModule, MatButtonModule, MatIconModule, MatListModule, TranslateModule],
})
export class ChangingHistoryComponent implements OnInit {
    @Input() data: VcHistoryElement<any>[] | undefined;
    @Input() i18n: FormioRendererI18n | undefined;
    @Input() index: number | undefined;
    @Input() form_file_name: string = '';
    @Input() last: boolean | undefined;
    @Input() showOldValues = true;
    /** Component will not show history fields which "key" is in this array. For example, to hide a "sort_index" or so. */
    @Input() hideFields: string[] = [];

    public labels: { key: string; value: string }[] = [];
    public differences: Difference[] = [];
    public formData: any[] | undefined;
    public patientInfo: PatientNameAndDob = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        room: '',
        ward: '',
        bed: '',
    };

    public currentLanguage: string = '';
    public modifiedBy: string = '';
    public modifiedAt: string = '';

    public constructor(
        // TODO: Is Dialog necessary? If not, remove it
        public dialog: MatDialog,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<FormModalResult>
    ) {
        this.currentLanguage = this.translate.currentLang;
    }

    /** this.data.data[0] is newer */
    public ngOnInit(): void {
        if (this.data) {
            this.differences = this.compareRecursively(this.data[0].data, this.data[1]?.data);
            this.modifiedAt = this.data[0]?.modifiedAt ?? 'Unbekannt';
            this.modifiedBy = `${this.data[0]?.modifiedBy.name} ${this.data[0]?.modifiedBy.surname} `;
        }
    }

    //#region Listeners
    public onConfirm(): void {
        this.dialogRef.close(true);
    }

    public onDismiss(): void {
        this.dialogRef.close(false);
    }

    //#endregion

    public async openDialog(): Promise<void> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {},
            height: 'auto',
        });
        try {
            const result = await firstValueFrom(dialogRef.afterClosed());
            if (result) {
                console.log('User confirmed the action');
                // Put your restore logic here
            } else {
                console.log('User dismissed the action');
            }
        } catch (error) {
            console.error('Error while closing the dialog:', error);
        }
    }

    private compareRecursively(obj1: any, obj2: any = {}, path: string[] = []): Difference[] {
        const differences: Difference[] = [];
        const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

        keys.forEach((key) => {
            if (
                key === 'modifiedAt' ||
                key === 'id' ||
                key === 'modifiedBy' ||
                key === 'created_at' ||
                key.includes('valid')
            ) {
                return;
            }

            const fullPath = path.concat(key).join('_');
            const translatedPath = this.translateElement(fullPath);

            const value1 = obj1[key];
            const value2 = obj2[key];

            if (typeof value1 === 'object' && value1 !== null && (value2 === undefined || typeof value2 === 'object')) {
                differences.push(...this.compareRecursively(value1, value2, path.concat(key)));
            } else if (value1 !== value2) {
                const oldVal = this.convertValueToString(value1);
                const newVal = this.convertValueToString(value2);
                differences.push({
                    key: translatedPath,
                    oldValue: oldVal,
                    newValue: newVal,
                });
            }
        });

        return differences;
    }

    private translateElement(element: any) {
        // console.warn(element);

        if (this.i18n && this.i18n[this.currentLanguage] && this.i18n[this.currentLanguage][element]) {
            return this.i18n[this.currentLanguage][element];
        } else {
            return element;
        }
    }

    private convertValueToString(value: any): string {
        if (typeof value === 'boolean') {
            return value ? 'true' : 'false';
        }
        if (value === null || value === undefined) {
            return '';
        }
        return value.toString();
    }
}
