import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExtendedRecord, FindingItem } from '../../med-view.component';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-finding-list',
    templateUrl: './finding-list.component.html',
    styleUrls: ['./finding-list.component.scss'],
    standalone: true,
    imports: [NgForOf, NgIf, DatePipe, NgClass, MatTooltip, MatIcon],
})
export class FindingListComponent implements OnInit, OnChanges {
    @Input({ required: true }) findingsList!: FindingItem<any>[];
    @Input({ required: true }) currentRecord!: ExtendedRecord | undefined;

    @Output() changeSelectedFinding: EventEmitter<FindingItem<any>> = new EventEmitter<FindingItem<any>>();

    constructor() {}

    ngOnInit() {}

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['findingsList'].currentValue.length > 0) {
        }
    }

    public selectFinding(fl: FindingItem<any>) {
        this.changeSelectedFinding.emit(fl);
    }
}
