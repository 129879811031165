import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent, AlertConfig, AlertResult } from '../modals/alert/alert.component';
import { firstValueFrom } from 'rxjs';
import { AlertInput } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    public constructor(private dialog: MatDialog) {}

    public async show(params: AlertConfig): Promise<AlertResult | undefined> {
        const d: MatDialogRef<AlertComponent, AlertResult> = this.dialog.open<AlertComponent, AlertConfig, AlertResult>(
            AlertComponent,
            { data: params, width: '450px', maxWidth: '90vw' }
        );

        return await firstValueFrom(d.afterClosed());
    }
}
