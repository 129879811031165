<div class="w-full h-full flex flex-col">
    <mat-toolbar>
        <div class="flex flex-row justify-between items-center w-full">
            <div>
                <button (click)="onZoomIn()" mat-icon-button matTooltip="Vergrößern">
                    <mat-icon>zoom_in</mat-icon>
                </button>
                <button (click)="onZoomOut()" mat-icon-button matTooltip="Verkleinern">
                    <mat-icon>zoom_out</mat-icon>
                </button>

                <button (click)="onReset()" mat-icon-button matTooltip="Zurücksetzen">
                    <mat-icon>refresh</mat-icon>
                </button>

                <button (click)="onPreviousImage()" mat-icon-button
                        matTooltip="Vorheriges Bild">
                    <mat-icon>navigate_before</mat-icon>
                </button>

                <button (click)="onNextImage()" mat-icon-button
                        matTooltip="Nächstes Bild">
                    <mat-icon>navigate_next</mat-icon>
                </button>
            </div>
            <div>
                <button (click)="toggleMetaDataOverlay()" mat-icon-button
                        matTooltip="Metadaten anzeigen">
                    <mat-icon>info</mat-icon>
                </button>

                <button (click)="onScreenShot()" mat-icon-button matTooltip="Screenshot">
                    <mat-icon>camera_alt</mat-icon>
                </button>

                @if (!fullscreen) {
                    <button mat-icon-button (click)="onFullScreen()"
                            matTooltip="Vollbild">
                        <mat-icon>fullscreen</mat-icon>
                    </button>
                }
            </div>

        </div>
    </mat-toolbar>

    <div (dragover)="$event.preventDefault()" [id]="viewportUUID" [ngClass]="{'fullscreen-content-container': fullscreen, 'content-container': !fullscreen}" class="h-full w-full"
         oncontextmenu="return false"></div>
</div>
