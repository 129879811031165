<div class="p-2 flex flex-col gap-2 w-full h-full bg-[var(--color-background-primary-color)]">
    <!-- Header bleibt oben fixiert -->
    <app-interactive-header
        (changeSelectedRecord)="selectRecord($event)"
        (changeSelectedRegion)="selectRegion($event)"
        (openDoctorFormular)="onClickOpenDoctorFormular()"
        (openLaborViewer)="openLaborViewer()"
        (openNurseFormular)="onClickOpenNurseFormular()"
        (openTextEditor)="onClickOpenTexteditor()"
        [records]="records"
        [regions]="regions"
    ></app-interactive-header>

    <!-- Flex-Container mit scrollbaren Spalten -->
    <div class="flex flex-row gap-1 flex-grow overflow-hidden">
        <!-- Linke Spalte: Finding-List -->
        <div class="w-1/3 h-full overflow-y-auto">
            <app-finding-list
                (changeSelectedFinding)="selectFinding($event)"
                [currentRecord]="getSelectedRecord()"
                [findingsList]="findingsList"
            ></app-finding-list>
        </div>

        <!-- Rechte Spalte: Finding-Viewer -->
        <div class="w-2/3 max-h-full bg-white">
            <app-finding-viewer
                (clickOnOpenFullscreen)="onClickOnOpenFullscreen()"
                (clickOnOpenLabTableFullscreen)="onClickOnOpenLabTableFullscreen()"
                (clickOnOpenPaintingTool)="onClickOnOpenPaintingTool()"
                (onClickMakeScreenshot)="saveScreenshot($event)"
                (onClickSaveForm)="saveForm($event)"
                [allLabResults]="laborTable"
                [selectedFinding]="getSelectedFinding()"
            ></app-finding-viewer>
        </div>
    </div>
</div>
