import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipListbox, MatChipOption, MatChipSelectionChange } from '@angular/material/chips';
import { NgForOf } from '@angular/common';
import { ExtendedRecord, ExtendedRegion } from '../../med-view.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatOption, MatSelect } from '@angular/material/select';
import { Region } from '../../../../models/patient-records.model';

@Component({
    selector: 'app-interactive-header',
    templateUrl: './interactive-header.component.html',
    styleUrls: ['./interactive-header.component.scss'],
    standalone: true,
    imports: [MatChipOption, NgForOf, MatIcon, MatIconButton, MatTooltip, MatSelect, MatOption, MatChipListbox],
})
export class InteractiveHeaderComponent implements OnInit {
    @Input({ required: true }) regions!: ExtendedRegion[];
    @Input({ required: true }) records!: ExtendedRecord[];

    @Output() changeSelectedRegion: EventEmitter<ExtendedRegion> = new EventEmitter<ExtendedRegion>();
    @Output() changeSelectedRecord: EventEmitter<ExtendedRecord> = new EventEmitter<ExtendedRecord>();
    @Output() openLaborViewer: EventEmitter<void> = new EventEmitter<void>();
    @Output() openNurseFormular: EventEmitter<void> = new EventEmitter<void>();
    @Output() openDoctorFormular: EventEmitter<void> = new EventEmitter<void>();
    @Output() openTextEditor: EventEmitter<void> = new EventEmitter<void>();

    public disableLabViewerButton: boolean = true;

    constructor() {}

    ngOnInit() {}

    public selectRegion(region: ExtendedRegion) {
        if (!region.selected) {
            this.disableLabViewerButton = region.code !== '23';
            this.changeSelectedRegion.emit(region);
        }
    }

    public selectRecord(record: ExtendedRecord) {
        this.changeSelectedRecord.emit(record);
    }

    public getSelectedRecord(): ExtendedRecord | undefined {
        return this.records.find((r: ExtendedRecord) => r.selected);
    }

    public clickOnOpenLaborViewer($event: MatChipSelectionChange) {
        const laborRegion: ExtendedRegion | undefined = this.regions.find((r: Region) => r.code === '23');
        if ($event.selected) {
            if (laborRegion) {
                laborRegion.selected = false;
                this.openLaborViewer.emit();
            }
        } else {
            if (laborRegion) {
                this.selectRegion(laborRegion);
            }
        }
    }

    public onClickOpenNurseFormular() {
        this.openNurseFormular.emit();
    }

    public onClickOpenDoctorFormular() {
        this.openDoctorFormular.emit();
    }

    public onClickOpenTextEditor() {
        this.openTextEditor.emit();
    }
}
