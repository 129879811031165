<div class="p-2 flex flex-row bg-white justify-end items-center gap-2 border-b">
    <button
        (click)="onClickOnOpenPaintingTool()"
        mat-icon-button
        matTooltip="Dokument bearbeiten">
        <mat-icon>draw</mat-icon>
    </button>
    <button
        (click)="onClickOnOpenFullscreen()"
        mat-icon-button
        matTooltip="Vollbild anzeigen">
        <mat-icon>fullscreen</mat-icon>
    </button>
</div>
