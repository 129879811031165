<mat-card>
    <mat-card-header>
        <mat-card-title>Server Settings</mat-card-title>
        <!-- <mat-card-subtitle>Subtitle</mat-card-subtitle> -->
    </mat-card-header>

    <mat-card-content>
        <!-- Load config from URL -->

        <!-- Detailed form -->
        <app-formio-renderer [form]="form" [(data)]="formData" (dataChange)="onFormDataChange()" (formButtonClick)="onFormButtonClick($event)">
        </app-formio-renderer>
    </mat-card-content>

    <mat-card-actions class="justify-end gap-1">
        <button mat-flat-button (click)="onClickOnSave()" [disabled]="isSaveDisabled">Save</button>
        <button mat-button (click)="onClickOnCancel()">Cancel</button>
    </mat-card-actions>
</mat-card>
