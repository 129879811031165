import { z } from 'zod';

export const ServerSettingsSchema = z.object({
    cpad2Server: z.string().url(),
    smartSettingsServer: z.string().url(),
    apiServer: z.string().url(),
    cpad2ServerGenerateJWT: z.boolean(),
    cpad2ServerSubscriberTokenUrl: z.string(),
    mercureBaseUrl: z.string().url(),
    mercureSubscriberToken: z.string(),
    loginConfigUrl: z.string().url(),
});

export type ServerSettings = z.infer<typeof ServerSettingsSchema>;
