import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ExtendedFinding, FindingItem } from '../../med-view.component';
import {
    FormioRendererComponent,
    FormioRendererData,
} from '../../../../components/data-interaction/formio-renderer/formio-renderer.component';
import * as pdfjsLib from 'pdfjs-dist';
import { MediaToolbarComponent } from '../media-toolbar/media-toolbar.component';
import { LabResultViewerComponent } from '../../../../components/patient-info/lab-result-viewer/lab-result-viewer.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { LabTableViewerComponent } from '../../../../components/patient-info/lab-table-viewer/lab-table-viewer.component';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';
import { DicomViewerComponent } from '../../../../components/dicom-viewer/dicom-viewer.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CanvasRendererService } from '../../services/canvas-renderer.service';
import { MatCardActions } from '@angular/material/card';
import { LabResult } from '../../../../models/view-content.models/view-content-clinic-domain.model';

@Component({
    selector: 'app-finding-viewer',
    templateUrl: './finding-viewer.component.html',
    styleUrls: ['./finding-viewer.component.scss'],
    standalone: true,
    providers: [CanvasRendererService],
    imports: [
        NgSwitch,
        NgSwitchCase,
        FormioRendererComponent,
        MediaToolbarComponent,
        NgIf,
        NgClass,
        MediaToolbarComponent,
        LabResultViewerComponent,
        MatIconButton,
        LabTableViewerComponent,
        MatIcon,
        MatToolbar,
        DicomViewerComponent,
        MatProgressSpinner,
        MatCardActions,
        MatButton,
    ],
})
export class FindingViewerComponent implements OnInit, OnChanges {
    @Input({ required: true }) selectedFinding!: FindingItem<any> | undefined;
    @Input() allLabResults!: FindingItem<{ allLabResults: LabResult[]; documentType: string }> | undefined;

    @Output() clickOnOpenPaintingTool: EventEmitter<void> = new EventEmitter<void>();
    @Output() clickOnOpenFullscreen: EventEmitter<void> = new EventEmitter<void>();
    @Output() clickOnOpenLabTableFullscreen: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClickMakeScreenshot: EventEmitter<ExtendedFinding> = new EventEmitter<ExtendedFinding>();
    @Output() onClickSaveForm: EventEmitter<FindingItem<ExtendedFinding>> = new EventEmitter<
        FindingItem<ExtendedFinding>
    >();

    public isLabTableShowLimits = true;
    protected readonly Object = Object;

    constructor(public canvasRendererService: CanvasRendererService) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.mjs';
    }

    ngOnInit() {}

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedFinding']?.currentValue) {
            switch (this.selectedFinding?.data.documentType) {
                case 'pdf':
                    await this.canvasRendererService.initCanvas(
                        'previewCanvasPDF',
                        'previewWrapperPDF',
                        this.selectedFinding,
                        this.selectedFinding.data.annotations,
                        false
                    );
                    break;
                case 'image':
                    await this.canvasRendererService.initCanvas(
                        'previewCanvasIMG',
                        'previewWrapperIMG',
                        this.selectedFinding,
                        this.selectedFinding.data.annotations,
                        false
                    );
                    break;
            }
        }

        this.allLabResults = changes['allLabResults']?.currentValue;
    }

    public onClickOnOpenPaintingTool() {
        this.clickOnOpenPaintingTool.emit();
    }

    public onClickOnOpenFullscreen() {
        this.clickOnOpenFullscreen.emit();
    }

    public onClickOnOpenLabTableFullscreen() {
        this.clickOnOpenLabTableFullscreen.emit();
    }

    public clickMakeScreenshot($event: ExtendedFinding) {
        this.onClickMakeScreenshot.emit($event);
    }

    public clickSaveForm($event: FindingItem<ExtendedFinding> | undefined) {
        this.onClickSaveForm.emit($event);
    }

    public onChangeFormData($event: FormioRendererData[]) {
        if (this.selectedFinding) this.selectedFinding.data.formioRendererData = $event;
    }
}
