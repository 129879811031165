<!-- Close button -->
<button class="!absolute right-4 top-4 z-50" mat-dialog-close mat-mini-fab>
    <mat-icon>cancel</mat-icon>
</button>

<mat-dialog-content>
    <!--<app-pdf-viewer
        *ngIf="documentType === 'pdf'"
        [src]="base64 ?? ''"
        class="w-full h-full"
    ></app-pdf-viewer>-->
    <div *ngIf="documentType === 'pdf'" [ngClass]="{'!overflow-y-auto': canvasRendererService.getCanvasInitializationStatus()}" class="w-full h-full !overflow-x-hidden overflow-y-hidden">
        <div class="w-full h-full" id="previewWrapperPDFf">
            <div *ngIf="!canvasRendererService.getCanvasInitializationStatus()" class="w-full h-full p-2 flex flex-col items-center justify-center bg-white shadow gap-4">
                <mat-spinner class="!h-12 !w-12"></mat-spinner>
                <div class="text-sm text-gray-600 font-light">L&auml;dt...</div>
            </div>
            <div [ngClass]="{'hidden': !canvasRendererService.getCanvasInitializationStatus()}">
                <canvas class="!w-full !h-full" id="previewCanvasPDFf"></canvas>
            </div>
        </div>
    </div>

    <div *ngIf="documentType === 'image'" [ngClass]="{'!overflow-y-auto': canvasRendererService.getCanvasInitializationStatus()}" class="w-full h-full !overflow-x-hidden overflow-y-hidden">
        <div class="w-full h-full" id="previewWrapperIMGg">
            <div *ngIf="!canvasRendererService.getCanvasInitializationStatus()" class="w-full h-full p-2 flex flex-col items-center justify-center bg-white shadow gap-4">
                <mat-spinner class="!h-12 !w-12"></mat-spinner>
                <div class="text-sm text-gray-600 font-light">L&auml;dt...</div>
            </div>
            <div [ngClass]="{'hidden': !canvasRendererService.getCanvasInitializationStatus()}">
                <canvas class="!w-full !h-full" id="previewCanvasIMGg"></canvas>
            </div>
        </div>
    </div>

    <!--<img
        *ngIf="documentType === 'image'"
        [src]="'data:image;base64,' + base64"
        alt=""
        class="h-full w-auto mx-auto object-contain"
    />-->

    <app-dicom-viewer
        *ngIf="documentType === 'dicom'"
        [base64]="base64"
        class="w-full h-full"
    ></app-dicom-viewer>

    <app-lab-table-viewer
        *ngIf="documentType === 'lab-table'"
        [labResults]="labResults"
        [numberOfColumnsToShow]="7"
        [showLimitsToggleButton]="true"
        class="block"
    ></app-lab-table-viewer>
</mat-dialog-content>
