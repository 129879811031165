<div class="sticky p-2 pb-0 top-0 w-full bg-white">
    <div class="pb-2 flex flex-row justify-between items-center">
        <div class="flex flex-row gap-2 items-center">
            <div>
                <span>{{ selectedFinding?.data?.filename }}</span>
            </div>
        </div>
        <div>
            <button (click)="onClickOnCancel()" mat-mini-fab>
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
</div>

<div class="relative h-[calc(100%-57px-57px)] w-full flex flex-col">
    <div class="flex flex-row w-full h-full">
        <div class="w-fit flex flex-col justify-between p-2 bg-white border-r sticky left-0">
            <button (click)="enableTextAdding()" mat-icon-button matTooltip="Text hinzufügen">
                <mat-icon>title</mat-icon>
            </button>
            <button (click)="toggleDrawingMode()" [matTooltip]="isDrawingMode ? 'Zeichenmodus deaktivieren' : 'Zeichenmodus aktivieren'" mat-icon-button>
                <mat-icon *ngIf="!isDrawingMode">edit</mat-icon>
                <mat-icon *ngIf="isDrawingMode">close</mat-icon>
            </button>
            <button (click)="toggleMarkingMode()" [matTooltip]="isMarkingMode ? 'Markierungsmodus deaktivieren' : 'Markierungsmodus aktivieren'" mat-icon-button>
                <mat-icon *ngIf="!isMarkingMode">format_paint</mat-icon>
                <mat-icon *ngIf="isMarkingMode">close</mat-icon>
            </button>
            <button (click)="addCircle()" mat-icon-button matTooltip="Kreis hinzufügen">
                <mat-icon>radio_button_unchecked</mat-icon>
            </button>
            <button (click)="addRect()" mat-icon-button matTooltip="Rechteck hinzufügen">
                <mat-icon>crop_square</mat-icon>
            </button>
            <button (click)="addArrow()" mat-icon-button matTooltip="Pfeil hinzufügen">
                <mat-icon>trending_flat</mat-icon>
            </button>
            <!--<button mat-icon-button matTooltip="Herunterladen">
                <mat-icon>download</mat-icon>
            </button>-->
            <button (click)="toggleTooltip($event, colorPalette, 'colorPalette')" mat-icon-button matTooltip="Farbauswahl">
                <mat-icon [ngStyle]="{'color': fabricSettings.color}">palette</mat-icon>
            </button>
            <button (click)="toggleTooltip($event, strokeWidth, 'strokeWidth')" mat-icon-button matTooltip="Linienstärke ändern">
                <mat-icon>line_weight</mat-icon>
            </button>
            <button (click)="toggleObjectVisibility()" [matTooltip]="areAnnotationsVisible ? 'Objekte ausblenden' : 'Objekte einblenden'" mat-icon-button>
                <mat-icon>{{ areAnnotationsVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
            <button (click)="deleteSelectedObject()" mat-icon-button matTooltip="Ausgewähltes Objekt l&ouml;schen">
                <mat-icon>delete</mat-icon>
            </button>
        </div>


        <div class="relative w-full h-full flex flex-col overflow-auto" id="PaintingToolWrapper">
            <div *ngIf="!canvasRendererService.getCanvasInitializationStatus()" class="w-full h-full flex flex-col items-center justify-center bg-white shadow gap-0">
                <mat-spinner class="!h-12 !w-12"></mat-spinner>
                <div class="text-sm text-gray-600 font-light mt-4">L&auml;dt...</div>
            </div>

            <div [ngClass]="{'hidden': !canvasRendererService.getCanvasInitializationStatus()}">
                <canvas class="!w-full !h-full" id="myCanvas"></canvas>
            </div>
        </div>
    </div>
</div>
<div class="sticky p-2 pt-0 bottom-0 w-full bg-white">
    <mat-divider></mat-divider>
    <div class="flex justify-end pt-2 gap-2">
        <button (click)="onClickOnCancel()" class="p-2" mat-stroked-button>{{ 'COMPONENT.ADMIN.FORM_MANAGER.btn_back' | translate }}</button>
        <button (click)="onClickOnSave()" class="p-2" mat-flat-button>{{ 'COMPONENT.ADMIN.FORM_MANAGER.btn_save' | translate }}</button>
    </div>
</div>
<ng-template #colorPalette>
    <div class="flex flex-col gap-2">
        <div class="text-gray-600 text-sm">W&auml;hle eine Farbe:</div>
        <div class="flex flex-row gap-1">
            <div (click)="updateColor('black')" class="cursor-pointer rounded-full shadow bg-black h-7 w-7 border" matTooltip="Standard"></div>
        </div>
        <div class="flex flex-row gap-1">
            <div (click)="updateColor('#1abc9c', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#1abc9c] h-7 w-7 border" matTooltip="TURQUOISE"></div>
            <div (click)="updateColor('#2ecc71', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#2ecc71] h-7 w-7 border" matTooltip="EMERALD"></div>
            <div (click)="updateColor('#3498db', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#3498db] h-7 w-7 border" matTooltip="PETER RIVER"></div>
            <div (click)="updateColor('#9b59b6', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#9b59b6] h-7 w-7 border" matTooltip="AMETHYST"></div>
            <div (click)="updateColor('#34495e', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#34495e] h-7 w-7 border" matTooltip="WET ASPHALT"></div>
            <div (click)="updateColor('#16a085', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#16a085] h-7 w-7 border" matTooltip="GREEN SEA"></div>
            <div (click)="updateColor('#27ae60', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#27ae60] h-7 w-7 border" matTooltip="NEPHRITIS"></div>
            <div (click)="updateColor('#2980b9', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#2980b9] h-7 w-7 border" matTooltip="BELIZE HOLE"></div>
            <div (click)="updateColor('#8e44ad', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#8e44ad] h-7 w-7 border" matTooltip="WISTERIA"></div>
            <div (click)="updateColor('#2c3e50', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#2c3e50] h-7 w-7 border" matTooltip="MIDNIGHT BLUE"></div>
        </div>
        <div class="flex flex-row gap-1">
            <div (click)="updateColor('#f1c40f', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#f1c40f] h-7 w-7 border" matTooltip="SUN FLOWER"></div>
            <div (click)="updateColor('#e67e22', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#e67e22] h-7 w-7 border" matTooltip="CARROT"></div>
            <div (click)="updateColor('#e74c3c', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#e74c3c] h-7 w-7 border" matTooltip="ALIZARIN"></div>
            <div (click)="updateColor('#ecf0f1', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#ecf0f1] h-7 w-7 border" matTooltip="CLOUDS"></div>
            <div (click)="updateColor('#95a5a6', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#95a5a6] h-7 w-7 border" matTooltip="CONCRETE"></div>
            <div (click)="updateColor('#f39c12', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#f39c12] h-7 w-7 border" matTooltip="ORANGE"></div>
            <div (click)="updateColor('#d35400', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#d35400] h-7 w-7 border" matTooltip="PUMPKIN"></div>
            <div (click)="updateColor('#c0392b', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#c0392b] h-7 w-7 border" matTooltip="POMEGRANATE"></div>
            <div (click)="updateColor('#bdc3c7', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#bdc3c7] h-7 w-7 border" matTooltip="SILVER"></div>
            <div (click)="updateColor('#7f8c8d', $event, colorPalette)" class="cursor-pointer rounded-full shadow bg-[#7f8c8d] h-7 w-7 border" matTooltip="ASBESTOS"></div>
        </div>
    </div>
</ng-template>

<ng-template #strokeWidth>
    <div class="flex flex-col gap-2">
        <div class="text-gray-600 text-sm">W&auml;hle eine Breite:</div>
        <div class="flex flex-col gap-2">
            <div (click)="updateStrokeWitdh(1, $event, strokeWidth)" [ngClass]="{'bg-gray-200': fabricSettings.width === 1}" class="flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-200">
                <div>1</div>
                <hr class="w-full h-[1px] !bg-black !text-black !border-black">
            </div>
            <div (click)="updateStrokeWitdh(2, $event, strokeWidth)" [ngClass]="{'bg-gray-200': fabricSettings.width === 2}" class="flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-200">
                <div>2</div>
                <hr class="w-full h-[2px] !bg-black !text-black !border-black">
            </div>
            <div (click)="updateStrokeWitdh(4, $event, strokeWidth)" [ngClass]="{'bg-gray-200': fabricSettings.width === 4}" class="flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-200">
                <div>4</div>
                <hr class="w-full h-[4px] !bg-black !text-black !border-black">
            </div>
            <div (click)="updateStrokeWitdh(6, $event, strokeWidth)" [ngClass]="{'bg-gray-200': fabricSettings.width === 6}" class="flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-200">
                <div>6</div>
                <hr class="w-full h-[6px] !bg-black !text-black !border-black">
            </div>
            <div (click)="updateStrokeWitdh(8, $event, strokeWidth)" [ngClass]="{'bg-gray-200': fabricSettings.width === 8}" class="flex flex-row gap-1 items-center cursor-pointer hover:bg-gray-200">
                <div>8</div>
                <hr class="w-full h-[8px] !bg-black !text-black !border-black">
            </div>

        </div>
    </div>
</ng-template>
