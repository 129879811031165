import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { LabResult } from '../../models/view-content.models/view-content-clinic-domain.model';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';
import { LabTableViewerComponent } from '../../components/patient-info/lab-table-viewer/lab-table-viewer.component';
import { DicomViewerComponent } from '../../components/dicom-viewer/dicom-viewer.component';
import { CanvasRendererService } from '../../pages/med-view/services/canvas-renderer.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FindingItem } from '../../pages/med-view/med-view.component';

@Component({
    selector: 'app-fullscreen-dialog',
    templateUrl: './fullscreen-dialog.component.html',
    styleUrls: ['./fullscreen-dialog.component.scss'],
    imports: [
        MatDialogClose,
        MatIcon,
        MatMiniFabButton,
        MatDialogContent,
        NgIf,
        LabTableViewerComponent,
        DicomViewerComponent,
        MatProgressSpinner,
        NgClass,
    ],
    standalone: true,
})
export class FullscreenDialogComponent {
    public base64?: string;
    public documentType!: string;
    public labResults: LabResult[] = [];
    public selectedFinding: FindingItem<any> = {} as FindingItem<any>;

    public constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: { data: any; documentType: string; selectedFinding: FindingItem<any> },
        public canvasRendererService: CanvasRendererService
    ) {
        this.initializeData();
    }

    /**
     * Initialisiert die Daten der Komponente.
     */
    private initializeData(): void {
        const { data, documentType, selectedFinding } = this.data;

        this.documentType = documentType || data.documentType;
        this.base64 = data?.base64;
        this.labResults = Array.isArray(data) ? data : [];
        this.selectedFinding = selectedFinding;

        console.log('Selected Finding:', this.selectedFinding);

        if (['pdf', 'image'].includes(this.documentType)) {
            this.initializeCanvas(this.documentType);
        }
    }

    /**
     * Initialisiert das Canvas basierend auf dem Dokumenttyp.
     * @param documentType Der Typ des Dokuments (`pdf` oder `image`).
     */
    private initializeCanvas(documentType: string): void {
        const canvasId = this.getCanvasId(documentType);
        const wrapperId = this.getWrapperId(documentType);

        this.canvasRendererService.initCanvas(
            canvasId,
            wrapperId,
            this.selectedFinding,
            this.selectedFinding?.data?.annotations ?? null,
            false
        );
    }

    /**
     * Gibt die Canvas-ID basierend auf dem Dokumenttyp zurück.
     * @param documentType Der Typ des Dokuments.
     * @returns Die Canvas-ID.
     */
    private getCanvasId(documentType: string): string {
        return documentType === 'pdf' ? 'previewCanvasPDFf' : 'previewCanvasIMGg';
    }

    /**
     * Gibt die Wrapper-ID basierend auf dem Dokumenttyp zurück.
     * @param documentType Der Typ des Dokuments.
     * @returns Die Wrapper-ID.
     */
    private getWrapperId(documentType: string): string {
        return documentType === 'pdf' ? 'previewWrapperPDFf' : 'previewWrapperIMGg';
    }
}
