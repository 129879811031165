const ProjectionMode = {
  MAX: 0,
  MIN: 1,
  AVERAGE: 2
};
var Constants = {
  ProjectionMode
};

export { ProjectionMode, Constants as default };
