import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    FormioRendererComponent,
    FormioRendererData,
} from '../../components/data-interaction/formio-renderer/formio-renderer.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CapacitorHttp } from '@capacitor/core';
import { ToolboxService } from '../../services/toolbox.service';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerSettings, ServerSettingsSchema } from '../../models/server-settings.model';

export interface ServerSettingsModalData {
    serverSettings: ServerSettings;
}

export interface ServerSettingsModalResult {
    role: 'save' | 'cancel';
    serverSettings?: ServerSettings;
}

@Component({
    selector: 'app-server-settings-modal',
    templateUrl: './server-settings-modal.component.html',
    styleUrls: ['./server-settings-modal.component.scss'],
    standalone: true,
    imports: [FormioRendererComponent, MatCardModule, MatButtonModule],
})
export class ServerSettingsModalComponent implements OnInit {
    public form = {
        components: [
            {
                label: 'Load from URL',
                columns: [
                    {
                        components: [
                            {
                                label: 'URL',
                                applyMaskOn: 'change',
                                tableView: true,
                                validateWhenHidden: false,
                                key: 'loginConfigUrl',
                                type: 'textfield',
                                input: true,
                            },
                        ],
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 10,
                        width: 10,
                    },
                    {
                        components: [
                            {
                                label: 'Load',
                                showValidations: false,
                                tableView: false,
                                key: 'load',
                                type: 'button',
                                input: true,
                                saveOnEnter: false,
                            },
                        ],
                        offset: 0,
                        push: 0,
                        pull: 0,
                        size: 'md',
                        currentWidth: 2,
                        width: 2,
                    },
                ],
                key: 'loadFromUrl',
                type: 'columns',
                input: false,
                tableView: false,
            },

            {
                label: 'Server URL',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'cpad2Server',
                type: 'textfield',
                input: true,
            },
            {
                label: 'Smart Settings URL',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'smartSettingsServer',
                type: 'textfield',
                input: true,
            },
            {
                label: 'API URL',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'apiServer',
                type: 'textfield',
                input: true,
            },
            {
                label: 'Generate Server JWT',
                tableView: false,
                validateWhenHidden: false,
                key: 'cpad2ServerGenerateJWT',
                type: 'checkbox',
                input: true,
            },
            {
                label: 'Server Subscriber Token URL',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'cpad2ServerSubscriberTokenUrl',
                type: 'textfield',
                input: true,
            },
            {
                label: 'Mercure Base URL',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'mercureBaseUrl',
                type: 'textfield',
                input: true,
            },
            {
                label: 'Mercure Subscriber Token',
                applyMaskOn: 'change',
                tableView: true,
                validateWhenHidden: false,
                key: 'mercureSubscriberToken',
                type: 'textfield',
                input: true,
            },
        ],
    };
    public formData: FormioRendererData[] = [];
    public isSaveDisabled = true;

    public constructor(
        private alertService: AlertService,
        private dialogRef: MatDialogRef<ServerSettingsModalResult>,
        @Inject(MAT_DIALOG_DATA)
        private data: ServerSettingsModalData,
        private dialog: MatDialog,
        private tb: ToolboxService,
        private translate: TranslateService
    ) {
    }

    public ngOnInit(): void {
        if (this.data.serverSettings) {
            this.formData = Object.keys(this.data.serverSettings).map((key) => {
                return { key, value: (this.data.serverSettings as any)[key] };
            });
        }
    }

    //#region Listeners

    public async onFormButtonClick($event: string): Promise<void> {
        let serverSettings: ServerSettings | undefined;
        if ($event === 'load') await this.tb.executeInLoading(async () => (serverSettings = await this.loadJson()));
        if (!serverSettings) {
            this.alertService.show({
                header: this.translate.instant('GENERAL.label_error'),
                message: this.translate.instant('COMPONENT.SETTINGS.SERVER_SETTINGS.alert_msg_error_load_url'),
                buttons: [{ role: 'ok', text: 'OK' }],
            });
            return;
        }

        // Extract data from loaded JSON to the form data
        const keys = Object.keys(serverSettings);
        const formData: FormioRendererData[] = [
            { key: 'url', value: this.formData.find((e) => e.key === 'url')?.value },
        ];
        for (const key of keys) {
            const value = (serverSettings as any)[key];
            formData.push({ key, value });
        }
        this.formData = formData;
        this.formData2ServerSettings();
    }

    public onClickOnCancel() {
        this.dialogRef.close({ role: 'cancel' });
    }

    public onClickOnSave() {
        const serverSettings = this.formData2ServerSettings();
        this.dialogRef.close({ role: 'save', serverSettings });
    }

    public onFormDataChange(): void {
        this.formData2ServerSettings();
    }
    //#endregion

    private async loadJson(): Promise<ServerSettings | undefined> {
        // Get and check the URL
        const urlValue = this.formData.find((e) => e.key === 'loginConfigUrl')?.value;
        if (typeof urlValue !== 'string') return;

        let url: URL | undefined;
        try {
            url = new URL(urlValue);
        } catch (e) {
            console.error('Invalid URL: ' + urlValue);
        }
        if (!url) return;

        // Get and check the remote JSON
        try {
            const res = await CapacitorHttp.get({ url: url.href });
            const result = ServerSettingsSchema.safeParse(res.data);
            if (result.success) {
                return result.data;
            } else {
                console.error('Invalid ServerSettings structure:', result.error.errors);
            }
        } catch (e) {
            console.error('Error fetching or parsing JSON:', e);
        }

        return;
    }

    private formData2ServerSettings(): ServerSettings | undefined {
        const res: any = {};
        for (const e of this.formData) {
            res[e.key] = e.value;
        }

        const result = ServerSettingsSchema.safeParse(res);
        this.isSaveDisabled = !result.success;
        return result.data;
    }
}
