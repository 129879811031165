const ObjectType = {
  ARRAY_BUFFER: 0,
  ELEMENT_ARRAY_BUFFER: 1,
  TEXTURE_BUFFER: 2
};
var Constants = {
  ObjectType
};

export { ObjectType, Constants as default };
